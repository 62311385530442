//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ReportPage',

  layout: 'page',

  data() {
    return {
      floor: null,
      floors: null,
      date: new Date(),
      dateMax: new Date(),
      listLoading: false,
      deliveryChart: {},
      collectionChart: {},
      summaryTableData: [],
      downloadChart: {},
    }
  },

  mounted() {
    this.fetchList()
  },

  methods: {
    async fetchList() {
      this.listLoading = true

      const res = await this.$axios.$get('/report/year', {
        params: {
          year: this.year,
          floor: this.floor,
        },
      })

      this.listLoading = false

      const { deliveryChart, collectionChart, downloadChart, floors } = res.data

      if (floors && !this.floors) {
        this.floors = floors
        this.floor = floors[0]
      }

      this.deliveryChart = deliveryChart
      this.collectionChart = collectionChart

      const {
        dataset: { source: deliveryData },
      } = deliveryChart

      const {
        dataset: { source: collectionData },
      } = collectionChart

      const summaryTableData = []

      for (let i = 1; i < deliveryData.length; i++) {
        const item = deliveryData[i]
        const collectionItem = collectionData[i]
        const date = item[0]

        summaryTableData.push({
          date,
          deliveryCount: item[1],
          recycleCount: item[2],
          utilization: item[3],
          collection: collectionItem[1],
          collectionNeed: collectionItem[2],
          collectionTotal: collectionItem[3],
        })
      }

      this.summaryTableData = summaryTableData.map((item) => {
        const [year, month] = item.date.split('/')

        item.link = `/report/detail?year=${year}&month=${month}`

        return item
      })

      this.downloadChart = downloadChart
    },
  },
}
