//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Table, TableColumn } from 'element-ui'

export default {
  components: {
    ElTable: Table,
    tableColumn: TableColumn,
  },

  props: {
    data: {
      type: Array,
      default: () => [],
    },
    showUserName: {
      type: Boolean,
      default: null,
    },
    showPayDevice: {
      type: Boolean,
      default: null,
    },
  },

  computed: {
    summaryPayTotal() {
      let total = 0

      this.data.forEach((item) => (total += item.payTotal))

      return total
    },
  },
}
