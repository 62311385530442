//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: 'name',
    },
    moreText: {
      type: String,
      default: '',
    },
    moreTextWidth: {
      type: Number,
      default: 100,
    },
    showAll: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      listOverFlow: 0,
    }
  },

  computed: {
    moreTextShow() {
      return this.moreText || `など、計${this.list.length}フロア`
    },
  },

  watch: {
    showAll(newValue) {
      if (newValue) {
        this.listOverFlow = 0
      } else {
        this.renderOverflow()
      }
    },
  },

  mounted() {
    this.renderOverflow()
  },

  methods: {
    renderOverflow() {
      const $list = this.$refs.tagsListRef
      const marginRight = this.moreTextWidth
      const maxWidth = $list.offsetWidth - marginRight
      const itemMarginX = 5

      let index = 0
      let width = 0

      for (; index < $list.children.length; index++) {
        width += $list.children[index].offsetWidth + itemMarginX
        if (width >= maxWidth) {
          break
        }
      }

      this.listOverFlow = index
    },
  },
}
