//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'PageLpDrink',

  layout: 'page',

  data() {
    return {
      goodsList: [
        {
          id: 1,
          title: '南アルプスの天然水',
          subTitle: '天然水SPARKLINGレモン',
          imgUrl: 'img/tmp/lp-spot-g1.jpg',
        },
        {
          id: 2,
          title: 'クラフトボス',
          subTitle: 'Black・Latte',
          imgUrl: 'img/tmp/lp-spot-g2.jpg',
        },
        {
          id: 3,
          title: 'トクホドリンク',
          subTitle: '黒烏龍茶・ジャスミン茶・緑茶',
          imgUrl: 'img/tmp/lp-spot-g3.jpg',
          width: '336px',
        },
      ],
    }
  },
}
