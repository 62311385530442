//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    cover: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    price: {
      type: Number,
      default: 0,
    },
    count: {
      type: Number,
      default: 0,
    },
    showInputNumber: {
      type: Boolean,
      default: false,
    },
    tag: {
      type: Object,
      default: () => {},
    },
    intro: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      countValue: this.count,
    }
  },

  methods: {
    inputCount(input) {
      if (!Number.isInteger(Number(input.data))) {
        input.target.value = this.countValue
      }
    },
    selectCount(value) {
      if (isNaN(value)) {
        value = 0

        this.countValue = value
      }

      this.$emit('count', value)
    },
  },
}
