//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'RemoteListPage',

  layout: 'page',

  data() {
    return {
      listLoading: false,
      couponAmount: 7000,
      list: [],
      selects: [],
      submitLoading: false,
      startAtList: [],
      endAtList: [],
    }
  },

  computed: {
    allowSubmit() {
      return this.list.some((item) => item.isSelect)
    },
  },

  mounted() {
    this.fetchList()
  },

  methods: {
    async fetchList(page) {
      this.listLoading = true

      const res = await this.$axios.$get('/remote/list')

      this.listLoading = false

      const { list, startAtList, endAtList } = res.data

      this.list = list.map((item) => {
        item.couponStartAtEdit = !item.couponStartAt
        item.couponEndAtEdit = !item.couponEndAt

        return item
      })

      this.startAtList = startAtList
      this.endAtList = endAtList
    },
    async submit() {
      const form = {}

      form.list = this.list
        .filter((item) => item.isSelect)
        .map((item) => {
          return {
            id: item.id,
            name: item.name,
            couponAmount: item.couponAmount,
            couponStartAt: item.couponStartAt,
            couponEndAt: item.couponEndAt,
            registerAt: item.registerAt,
          }
        })

      this.submitLoading = true

      const res = await this.$axios.post('/remote/list/update', form)

      this.submitLoading = false

      if (res.status !== 200) {
        return
      }

      this.$toast({
        message: '保存しました',
      })

      this.list = this.list.map((item) => {
        item.couponStartAtEdit = !item.couponStartAt
        item.couponEndAtEdit = !item.couponEndAt

        return item
      })

      this.$refs.tableRemote.clearSelect()
    },
  },
}
