//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ErrorPage',

  auth: false,

  layout: 'page',

  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    status() {
      return this.error?.status || this.error?.statusCode
    },
    title() {
      switch (this.status) {
        case 404:
          return 'お探しのページが見つかりません'

        default:
          return 'エラー'
      }
    },
    content() {
      switch (this.status) {
        case 404:
          return 'お探しのページが見つかりませんでした。\n左のメニューから他のページをご覧ください。'

        default:
          return 'システムエラ一が発生しました\nお手数ですが、しばらく時間を置いてから再度お試しください。'
      }
    },
  },
}
