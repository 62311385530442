import { render, staticRenderFns } from "./cutlery.vue?vue&type=template&id=0ac169b0&"
import script from "./cutlery.vue?vue&type=script&lang=js&"
export * from "./cutlery.vue?vue&type=script&lang=js&"
import style0 from "./cutlery.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DatePicker: require('/home/runner/work/myody_front/myody_front/components/date/DatePicker.vue').default,FormItem: require('/home/runner/work/myody_front/myody_front/components/form/FormItem.vue').default,Form: require('/home/runner/work/myody_front/myody_front/components/form/Form.vue').default,GoodsOdgGird: require('/home/runner/work/myody_front/myody_front/components/goods/GoodsOdgGird.vue').default,Card: require('/home/runner/work/myody_front/myody_front/components/card/Card.vue').default,Col: require('/home/runner/work/myody_front/myody_front/components/col/Col.vue').default,ListLabel: require('/home/runner/work/myody_front/myody_front/components/list/ListLabel.vue').default,ListLabelItem: require('/home/runner/work/myody_front/myody_front/components/list/ListLabelItem.vue').default,Button: require('/home/runner/work/myody_front/myody_front/components/button/Button.vue').default,Row: require('/home/runner/work/myody_front/myody_front/components/row/Row.vue').default})
