//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { addMonths } from 'date-fns'
import { formatDate } from '@/plugins/filters'

export default {
  name: 'CutleryHistorySelectPage',

  layout: 'page',

  data() {
    return {
      date: addMonths(new Date(), 1),
      formLoading: false,
      floor: null,
      floors: null,
    }
  },

  computed: {
    month() {
      return formatDate(this.date, 'yyyy-MM')
    },
  },

  mounted() {
    this.fetchOptions()
  },

  methods: {
    async fetchOptions() {
      this.formLoading = true

      const res = await this.$axios.$get('/equipments/history/floors', {
        params: {
          month: this.month,
        },
      })

      this.formLoading = false

      const { floors } = res.data

      if (!floors?.length) {
        this.$router.push('/cutlery/history')

        return
      }

      this.floors = floors
      this.floor = floors[0].id
    },
    save() {
      this.$router.push({
        path: '/cutlery/history',
        query: {
          floor: this.floor,
        },
      })
    },
  },
}
