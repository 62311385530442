//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Calendar } from 'element-ui'
import {
  getDay,
  isSameMonth,
  isBefore,
  differenceInCalendarMonths,
} from 'date-fns'
import { formatDate } from '@/plugins/filters'

export default {
  components: {
    calendar: Calendar,
  },

  filters: {
    day(date) {
      return formatDate(date, 'd')
    },
  },

  props: {
    value: {
      type: Date,
      default: null,
    },
    renderData: {
      type: Array,
      default: () => [],
    },
    disabledSelected: {
      type: Boolean,
      defalt: false,
    },
    disabledPrevMonth: {
      type: Boolean,
      default: false,
    },
    selectRange: {
      type: Array,
      default: () => [],
    },
    selectableDates: {
      type: Array,
      default: () => [],
    },
    holidays: {
      type: Array,
      default: () => [],
    },
    showTodayNav: {
      type: Boolean,
      default: false,
    },
    statusMap: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      selectedValue: this.value,
    }
  },

  computed: {
    renderDataMap() {
      const map = {}

      for (let i = 0; i < this.renderData.length; i++) {
        const item = this.renderData[i]

        if (item.date) {
          const itemKey = new Date(item.date)

          // if (map[itemKey]) {
          //   map[itemKey].more = [...map[itemKey].more, item]
          // } else {
          //   map[itemKey] = item
          // }

          if (!Array.isArray(item.status)) {
            item.status = [item.status]
          }

          map[itemKey] = item
        }
      }

      return map
    },
    renderDisabledPrevMonth() {
      return this.disabledPrevMonth && isSameMonth(this.value, new Date())
    },
    holidaysMap() {
      const map = {}

      for (let i = 0; i < this.holidays.length; i++) {
        const item = this.holidays[i]

        if (item.date) {
          map[new Date(item.date)] = true
        }
      }

      return map
    },
  },

  watch: {
    value(newValue) {
      this.selectedValue = newValue
    },
    selectedValue(newValue, oldValue) {
      let updateValue = newValue

      // handle next, prev month button
      if (
        updateValue?.getDate() === 1 &&
        Math.abs(differenceInCalendarMonths(updateValue, oldValue))
      ) {
        const autoSelectDate = this.autoSelectDate(updateValue)

        if (autoSelectDate) {
          this.$emit('input', autoSelectDate)

          return
        }
      }

      if (this.isDisabled(updateValue) && !this.isDisabled(oldValue)) {
        this.$emit('select:error', updateValue)

        updateValue = oldValue

        this.$nextTick(() => {
          this.selectedValue = oldValue
        })
      }

      this.$emit('input', updateValue)
    },
  },

  methods: {
    isSatDay(date) {
      return getDay(date) === 6
    },
    isSunDay(date) {
      return getDay(date) === 0
    },
    isHoliday(date) {
      return this.holidaysMap[date]
    },
    isDisabled(date) {
      if (this.selectableDates.length > 0) {
        return !this.selectableDates.includes(formatDate(date, 'yyyy-MM-dd'))
      }

      if (this.selectRange.length > 0) {
        const [startDate, endDate] = this.selectRange

        if (startDate && isBefore(date, startDate)) {
          return true
        }

        if (endDate && isBefore(endDate, date)) {
          return true
        }
      }

      return false
    },
    autoSelectDate(date) {
      if (this.selectableDates.length > 0) {
        return new Date(this.selectableDates.slice().sort()[0])
      }

      if (this.selectRange.length < 1) {
        return date
      }

      const [startDate, endDate] = this.selectRange

      if (startDate && isBefore(date, startDate)) {
        if (isSameMonth(date, startDate)) {
          return new Date(startDate)
        }

        return null
      }

      if (endDate && isBefore(endDate, date)) {
        if (isSameMonth(date, endDate)) {
          return new Date(endDate)
        }

        return null
      }

      return date
    },
  },
}
