import { render, staticRenderFns } from "./page.vue?vue&type=template&id=260817da&"
import script from "./page.vue?vue&type=script&lang=js&"
export * from "./page.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Header: require('/home/runner/work/myody_front/myody_front/components/Header.vue').default,SideNav: require('/home/runner/work/myody_front/myody_front/components/SideNav.vue').default,PageWrap: require('/home/runner/work/myody_front/myody_front/components/page/PageWrap.vue').default,Footer: require('/home/runner/work/myody_front/myody_front/components/Footer.vue').default,LayoutWrap: require('/home/runner/work/myody_front/myody_front/components/LayoutWrap.vue').default})
