//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    value: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: 'default',
    },
    options: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      radioOptions: this.options.slice(),
    }
  },

  mounted() {
    if (this.value) {
      this.renderOptions(this.value)
    }
  },

  methods: {
    renderOptions(id) {
      this.radioOptions = this.radioOptions.map((item) => {
        if (item.id === id) {
          item.active = true
        } else {
          item.active = false
        }

        return item
      })
    },
    select(option) {
      if (option?.active) {
        return
      }

      this.renderOptions(option.id)

      this.$emit('input', option.id)
      this.$emit('change', option.id)
    },
  },
}
