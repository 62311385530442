//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { addMonths } from 'date-fns'
import { formatDate } from '@/plugins/filters'

export default {
  name: 'OdgSelectPage',

  layout: 'page',

  data() {
    return {
      date: addMonths(new Date(), 1),
      formLoading: false,
      floor: null,
      floors: null,
    }
  },

  computed: {
    isAddon() {
      return this.$route.query.isAddon === 'true'
    },
    month() {
      return formatDate(this.date, 'yyyy-MM')
    },
    odgUrl() {
      return this.isAddon ? '/odg/addon' : '/odg/request'
    },
  },

  watch: {
    isAddon() {
      this.fetchOptions()
    },
  },

  mounted() {
    this.fetchOptions()
  },

  methods: {
    async fetchOptions() {
      this.formLoading = true

      const res = await this.$axios.$get('/frozen/floors', {
        params: {
          month: this.month,
          isAddon: this.isAddon,
        },
      })

      this.formLoading = false

      const { floors } = res.data

      if (!floors?.length) {
        this.$router.push(this.odgUrl)

        return
      }

      this.floors = floors
      this.floor = floors[0].id
    },
    save() {
      this.$router.push({
        path: this.odgUrl,
        query: {
          floor: this.floor,
        },
      })
    },
  },
}
