//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    linkTo: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: null,
      required: true,
    },
    typeName: {
      type: String,
      default: '',
    },
    fields: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      typeMap: {
        product: {
          color: '#dc5014',
        },
        monthly: {
          color: '#dc5014',
        },
        addon: {
          color: '#dc5014',
        },
        cutlery: {
          color: '#5e5f5e',
        },
        cancel: {
          color: '#5e5f5e',
        },
      },
    }
  },

  computed: {
    color() {
      return this.typeMap[this.type].color || '#dc5014'
    },
  },
}
