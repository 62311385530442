//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'CompanyManagerPage',

  layout: 'page',

  async asyncData({ $axios, query }) {
    const res = await $axios.$get('/company/detail', {
      params: {
        id: query.id,
      },
    })

    if (!res.data) {
      return {}
    }

    const { id, managers } = res.data

    return {
      id,
      managers,
    }
  },

  data() {
    const { query } = this.$route

    return {
      id: query.id,
      managers: [],
      formLoading: false,
    }
  },

  methods: {
    addManager() {
      this.managers.push({
        name: '',
        email: '',
      })
    },
    delManager(index) {
      this.managers.splice(index, 1)
    },
    async save() {
      const form = {
        id: this.id,
        managers: this.managers,
      }

      this.formLoading = true

      const res = await this.$axios.post('/company/info', form)

      this.formLoading = false

      if (res.status !== 200) {
        return
      }

      this.$toast({
        message: '保存しました',
      })

      this.$router.push('/company/detail')
    },
  },
}
