//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'DownloadPage',

  beforeRouteUpdate(to, from, next) {
    if (to.name === 'download') {
      this.tab = this.tabs[0].id
      this.onTabChange(this.tab)

      return
    }

    this.tab = to.name.replace('download-', '')

    next()
  },

  layout: 'page',

  data() {
    return {
      tab: null,
      tabs: [],
      pageLoading: false,
    }
  },

  head() {
    return {
      bodyAttrs: {
        class: `${this.$options.name}-${this.tab}`,
      },
    }
  },

  async mounted() {
    await this.fetchTabs()
  },

  methods: {
    fetchTabs() {
      this.tabs = this.$store.state.downloadList.filter((item) => item.display)
      this.tab = this.$route.name.replace('download-', '')
    },
    onTabChange(tab) {
      this.$router.push(`/download/${tab}`)
    },
  },
}
