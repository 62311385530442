//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'PageLpSpot',

  layout: 'page',

  data() {
    return {
      caseList: [
        {
          id: 1,
          title: 'Case 1',
          intro:
            '月1回の全社イベントで野菜を配布して、従業員に健康を意識させたい',
          imgUrl: 'img/tmp/lp-planup-item1.png',
        },
        {
          id: 2,
          title: 'Case 2',
          intro: '地方メンバーへの施策として野菜を提供したい',
          imgUrl: 'img/tmp/lp-planup-item2.png',
        },
        {
          id: 3,
          title: 'Case 3',
          intro:
            'コロナで出社制限中の為、通常の配達サービスは停止しているが、月1〜2回ほど気軽に頼めると嬉しい',
          imgUrl: 'img/tmp/lp-planup-item3.png',
        },
      ],
    }
  },
}
