import { render, staticRenderFns } from "./consumption.vue?vue&type=template&id=198112a6&"
import script from "./consumption.vue?vue&type=script&lang=js&"
export * from "./consumption.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Option: require('/home/runner/work/myody_front/myody_front/components/option/Option.vue').default,Select: require('/home/runner/work/myody_front/myody_front/components/select/Select.vue').default,FormItem: require('/home/runner/work/myody_front/myody_front/components/form/FormItem.vue').default,DatePicker: require('/home/runner/work/myody_front/myody_front/components/date/DatePicker.vue').default,Button: require('/home/runner/work/myody_front/myody_front/components/button/Button.vue').default,Form: require('/home/runner/work/myody_front/myody_front/components/form/Form.vue').default})
