//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'MobileProducts',

  layout: 'mobile',

  data() {
    return {
      productCats: [
        {
          id: 0,
          name: '全ての商品',
        },
        {
          id: 1,
          name: '飲料',
        },
        {
          id: 2,
          name: 'フルーツ',
        },
        {
          id: 3,
          name: '野菜',
        },
      ],
      productCat: 0,
      list: [
        {
          cover: 'http://dummyimage.com/164x134',
          title: 'カットフルーツ',
          intro:
            'ジューシーなグレープフルーツ、オレンジ、パイン等のフルーツを食べやすくカット',
          price: 100,
          sellCount: 10,
          tag: {
            id: 1,
            label: '冷',
          },
        },
        {
          cover: 'http://dummyimage.com/164x134',
          title: 'カットフルーツ',
          intro: 'ジューシーなグレープフルーツ',
          price: 101,
          sellCount: 10,
          tag: {
            id: 11,
            label: '冷',
          },
        },
        {
          cover: 'http://dummyimage.com/164x134',
          title: 'カットフルーツ',
          intro:
            'カットフルーツ, オレンジ、パイン等のフルーツを食べやすくカット...',
          price: 102,
          sellCount: 10,
          tag: {
            id: 13,
            label: '冷',
          },
        },
        {
          cover: 'http://dummyimage.com/164x134',
          title: 'カットフルーツ',
          intro: 'ジューシーなグレープフルーツ、オレンジ、',
          price: 103,
          sellCount: 232,
          tag: {
            id: 15,
            label: '冷',
          },
        },
        {
          cover: 'http://dummyimage.com/164x134',
          title: 'カットフルーツ',
          intro: 'ジューシーなグレープフルーツ、オレンジ、',
          price: 42,
          sellCount: 232,
          tag: {
            id: 16,
            label: '冷',
          },
        },
        {
          cover: 'http://dummyimage.com/164x134',
          title: 'カットフルーツ',
          intro: 'ジューシーなグレープフルーツ、オレンジ、',
          price: 42,
          sellCount: 232,
          tag: {
            id: 17,
            label: '冷',
          },
        },
      ],
      totalPrice: 0,
      count: 0,
      totalCount: 0,
      productDetail: {},
      isShowDetail: false,
      isShowCart: false,
      cartList: [],
    }
  },

  head() {
    return {
      bodyAttrs: {
        class: this.$options.name,
      },
    }
  },

  computed: {
    isLandscape() {
      return window.orientation === 90
    },
  },

  methods: {
    order() {
      this.$router.push('/mobile/order')
    },
    showDetail(detail) {
      this.productDetail = detail
      this.isShowDetail = true
    },
    closeDetail() {
      this.productDetail = {}
      this.isShowDetail = false
    },
    closeCart() {
      this.isShowCart = false
    },
    showCart(detail) {
      this.cartList.push({
        ...detail,
        count: 1,
        tag: {},
        intro: '',
      })

      this.totalPrice += detail.price
      this.totalCount += 1

      this.isShowCart = true
    },
  },
}
