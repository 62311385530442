import { render, staticRenderFns } from "./DateYearPager.vue?vue&type=template&id=3a77a1a5&"
import script from "./DateYearPager.vue?vue&type=script&lang=js&"
export * from "./DateYearPager.vue?vue&type=script&lang=js&"
import style0 from "./DateYearPager.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/home/runner/work/myody_front/myody_front/components/button/Button.vue').default,ButtonGroup: require('/home/runner/work/myody_front/myody_front/components/button/ButtonGroup.vue').default})
