//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'OdgRequestComplete',

  layout: 'page',

  data() {
    const { isAddon } = this.$route.query

    return {
      isAddon,
    }
  },

  computed: {
    typeTitle() {
      return this.isAddon ? '追加注文' : '定期リクエスト'
    },
  },
}
