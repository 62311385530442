//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'PasswordresetNewPage',

  auth: false,

  data() {
    const { reset_token: resetToken } = this.$route.query

    return {
      formLoading: false,
      password: '',
      password2: '',
      resetToken,
    }
  },

  created() {
    if (this.$auth.loggedIn) {
      this.$router.push('/')
    }
  },

  methods: {
    verifyPost(form) {
      const errMsgs = []

      if (!form.password || !form.passwordConfirm) {
        errMsgs.push('完全に記入してください')
      }

      if (form.password !== form.passwordConfirm) {
        errMsgs.push('パスワードは同じである必要があります')
      }

      if (errMsgs.length < 1) {
        return true
      }

      this.$message({
        type: 'error',
        message: errMsgs[0],
      })

      return false
    },
    async postReset() {
      const form = {
        password: this.password,
        passwordConfirm: this.password2,
        resetToken: this.resetToken,
      }

      if (!this.verifyPost(form)) {
        return
      }

      this.formLoading = true

      const res = await this.$axios.post('/auth/password/reset', form)

      this.formLoading = false

      if (res.status !== 200) {
        switch (res.status) {
          case 401:
            this.$router.replace('/login')
            break
          default:
            break
        }

        return
      }

      this.$router.replace('/passwordreset/success')
    },
  },
}
