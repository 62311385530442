//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import { formatDate } from '@/plugins/filters'

export default {
  props: {
    cover: {
      type: String,
      default: '',
    },
    goodsTitle: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    perSetCount: {
      type: Number,
      default: 0,
    },
    count: {
      type: Number,
      default: 0,
    },
    countMax: {
      type: Number,
      default: 10000,
    },
    downloadButtons: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      countValue: this.count,
      prevCountValue: this.count,
    }
  },

  watch: {
    count(newValue) {
      if (newValue !== this.countValue) {
        this.countValue = newValue
      }
    },
  },

  methods: {
    inputCount(input) {
      if (!Number.isInteger(Number(input.data))) {
        input.target.value = this.countValue
      }
    },
    selectCount(value, oldValue) {
      this.prevCountValue = oldValue

      if (isNaN(value)) {
        value = 0

        this.countValue = value
      }

      this.$emit('count', value)
    },
    // TODO：currently stop, click event remove from element
    proxyEvent(event) {
      const target =
        event.target.className === 'el-icon-plus'
          ? event.target.parentNode
          : event.target

      const inputNumberButtons = [
        'el-input-number__decrease',
        'el-input-number__increase',
      ]

      if (inputNumberButtons.includes(target.className)) {
        const data = {
          name: 'Input Number - Button',
          button: target.className,
          prevValue: this.prevCountValue,
          value: this.countValue,
          path: this.$route.path,
        }

        this.$nextTick(() => {
          this.$report({
            title: `${data.path}: ${data.name}`,
            tag: 'click',
            event: data,
          })
        })
      }
    },
    // TODO：currently stop, click event remove from element
    inputNumberBlur() {
      this.$nextTick(() => {
        this.$report({
          title: `${this.$route.path}: Input Number - Text`,
          tag: 'input',
          event: {
            name: 'Input Number - Text',
            prevValue: this.prevCountValue,
            value: this.countValue,
            path: this.$route.path,
          },
        })
      })
    },
  },
}
