import { render, staticRenderFns } from "./request.vue?vue&type=template&id=85953342&"
import script from "./request.vue?vue&type=script&lang=js&"
export * from "./request.vue?vue&type=script&lang=js&"
import style0 from "./request.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RadioOptions: require('/home/runner/work/myody_front/myody_front/components/radio/RadioOptions.vue').default,Checkbox: require('/home/runner/work/myody_front/myody_front/components/checkbox/Checkbox.vue').default,Button: require('/home/runner/work/myody_front/myody_front/components/button/Button.vue').default,GoodsOdyList: require('/home/runner/work/myody_front/myody_front/components/goods/GoodsOdyList.vue').default,Card: require('/home/runner/work/myody_front/myody_front/components/card/Card.vue').default,Col: require('/home/runner/work/myody_front/myody_front/components/col/Col.vue').default,RadioButton: require('/home/runner/work/myody_front/myody_front/components/radio/RadioButton.vue').default,RadioGroup: require('/home/runner/work/myody_front/myody_front/components/radio/RadioGroup.vue').default,ListLabel: require('/home/runner/work/myody_front/myody_front/components/list/ListLabel.vue').default,ListLabelItem: require('/home/runner/work/myody_front/myody_front/components/list/ListLabelItem.vue').default,Row: require('/home/runner/work/myody_front/myody_front/components/row/Row.vue').default})
