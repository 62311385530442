//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    countMax: {
      type: Number,
      default: undefined,
    },
  },

  methods: {
    countChange(item, count) {
      item.count = count

      this.$emit('change', item)
    },
  },
}
