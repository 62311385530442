//
//
//
//
//
//
//
//
//
//

import { Steps, Step } from 'element-ui'

export default {
  name: 'Steps',

  components: {
    steps: Steps,
    step: Step,
  },

  props: {
    steps: {
      type: Array,
      default: () => [],
    },
  },
}
