import { render, staticRenderFns } from "./ListNews.vue?vue&type=template&id=49ff8e52&"
import script from "./ListNews.vue?vue&type=script&lang=js&"
export * from "./ListNews.vue?vue&type=script&lang=js&"
import style0 from "./ListNews.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TagList: require('/home/runner/work/myody_front/myody_front/components/tag/TagList.vue').default,Link: require('/home/runner/work/myody_front/myody_front/components/link/Link.vue').default,Card: require('/home/runner/work/myody_front/myody_front/components/card/Card.vue').default})
