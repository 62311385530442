//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    labelTips: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number, Array],
      default: '',
    },
  },

  computed: {
    _value() {
      if (!Array.isArray(this.value)) {
        return [this.value]
      }

      return this.value
    },
  },
}
