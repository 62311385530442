//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'PasswordresetPage',

  auth: false,

  data() {
    return {
      formLoading: false,
      username: '',
      email: '',
    }
  },

  created() {
    if (this.$auth.loggedIn) {
      this.$router.push('/')
    }
  },

  methods: {
    async postEmail() {
      this.formLoading = true

      const form = {
        username: this.username,
        email: this.email,
      }

      const res = await this.$axios.post('/auth/password/send', form)

      this.formLoading = false

      if (res.status !== 200) {
        return
      }

      this.$router.replace('/passwordreset/complete')
    },
  },
}
