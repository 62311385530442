//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'MobileOrder',

  layout: 'mobile',

  data() {
    return {
      list: [
        {
          cover: 'http://dummyimage.com/164x134',
          title: 'カットフルーツ',
          intro: '手軽に食べられるドレッシング付きのミニサラダ。',
          price: 100,
          sellCount: 10,
          tag: {
            id: 1,
            label: '冷',
            color: '#00aa64',
          },
          count: 2,
        },
      ],
      totalPrice: 88000,
    }
  },
}
