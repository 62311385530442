//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'CompanyInfoPage',

  layout: 'page',

  async asyncData({ $axios, query }) {
    const res = await $axios.$get('/company/detail', {
      params: {
        id: query.id,
      },
    })

    if (!res.data) {
      return {}
    }

    const { id, companyInfo } = res.data

    return {
      id,
      name: companyInfo.name,
      floor: companyInfo.floor,
      finalMonth: companyInfo.finalMonth,
      phone: companyInfo.phone,
      userCount: companyInfo.userCount,
    }
  },

  data() {
    const { query } = this.$route

    return {
      id: query.id,
      name: null,
      floor: null,
      finalMonth: null,
      phone: null,
      userCount: null,
      industryOptions: [
        {
          label: '小売',
          value: '小売',
        },
        {
          label: '業種2',
          value: '業種2',
        },
        {
          label: '業種3',
          value: '業種3',
        },
      ],
      finalMonthOptions: [
        {
          label: '一',
          value: '一',
        },
        {
          label: '1',
          value: 1,
        },
        {
          label: '2',
          value: 2,
        },
        {
          label: '3',
          value: 3,
        },
        {
          label: '4',
          value: 4,
        },
        {
          label: '5',
          value: 5,
        },
        {
          label: '6',
          value: 6,
        },
        {
          label: '7',
          value: 7,
        },
        {
          label: '8',
          value: 8,
        },
        {
          label: '9',
          value: 9,
        },
        {
          label: '10',
          value: 10,
        },
        {
          label: '11',
          value: 11,
        },
        {
          label: '12',
          value: 12,
        },
      ],
      formLoading: false,
    }
  },

  methods: {
    userCountChange(value) {
      if (value < 0) {
        this.userCount = 0
      }
    },
    async save() {
      const form = {
        id: this.id,
        companyInfo: {
          floor: this.floor,
          finalMonth: this.finalMonth,
          phone: this.phone,
          userCount: this.userCount,
        },
      }

      this.formLoading = true

      const res = await this.$axios.post('/company/info', form)

      this.formLoading = false

      if (res.status !== 200) {
        return
      }

      this.$toast({
        message: '保存しました',
      })

      this.$router.push('/company/detail')
    },
  },
}
