import { render, staticRenderFns } from "./PageLogin.vue?vue&type=template&id=1a09c0b2&"
var script = {}
import style0 from "./PageLogin.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/home/runner/work/myody_front/myody_front/components/button/Button.vue').default,Link: require('/home/runner/work/myody_front/myody_front/components/link/Link.vue').default,Col: require('/home/runner/work/myody_front/myody_front/components/col/Col.vue').default,Row: require('/home/runner/work/myody_front/myody_front/components/row/Row.vue').default,PageWrap: require('/home/runner/work/myody_front/myody_front/components/page/PageWrap.vue').default})
