import { render, staticRenderFns } from "./_id.vue?vue&type=template&id=008e11d9&"
import script from "./_id.vue?vue&type=script&lang=js&"
export * from "./_id.vue?vue&type=script&lang=js&"
import style0 from "./_id.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/home/runner/work/myody_front/myody_front/components/button/Button.vue').default,PageHeadActions: require('/home/runner/work/myody_front/myody_front/components/page/PageHeadActions.vue').default,GoodsHistoryItem: require('/home/runner/work/myody_front/myody_front/components/goods/GoodsHistoryItem.vue').default,GoodsHistoryTable: require('/home/runner/work/myody_front/myody_front/components/goods/GoodsHistoryTable.vue').default,Card: require('/home/runner/work/myody_front/myody_front/components/card/Card.vue').default})
