//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Scrollbar } from 'element-ui'

export default {
  components: {
    'el-scrollbar': Scrollbar,
  },

  data() {
    return {
      isCollapse: !this.$device.isDesktop,
      downloadList: this.$store.state.downloadList.filter(
        (item) => item.display
      ),
    }
  },

  computed: {
    planYasai() {
      return this.$auth.user.plans.yasai
    },
    planGohan() {
      return this.$auth.user.plans.gohan
    },
    planRemote() {
      return this.$auth.user.plans?.remote
    },
    planEquipment() {
      return this.$auth.user.plans?.equipment
    },
    planEquipmentUrl() {
      return this.$auth.user.plans?.equipment.url
    },
    // planSnack() {
    //   return this.$auth.user.plans?.snack.display
    // },
    planDonwload() {
      return this.$auth.user.plans?.download
    },
    // planYasaipay() {
    //   return this.$auth.user.plans?.download.yasaipay?.display
    // },
    // planConsumption() {
    //   return this.$auth.user.plans?.download.consumption?.display
    // },
    // planGohanEquipment() {
    //   return this.$auth.user.setting?.gohanEquipment
    // },
    // planYasaiEquipment() {
    //   return this.$auth.user.setting?.yasaiEquipment
    // },
    // planReport() {
    //   return this.$auth.user.setting?.report
    // },
  },

  methods: {
    checkShow(opts) {
      const { display, ...subs } = opts
      const subsAry = Object.values(subs)

      if (subsAry.length > 0 && subsAry[0].display !== undefined) {
        return subsAry.some((item) => item.display)
      }

      return display
    },
    linkTo(url) {
      if (url) {
        window.open(url, '_blank')
      }
    },
  },
}
