//
//
//
//
//
//

export default {
  props: {
    src: {
      required: true,
      type: String,
      default: '',
    },
    rotate: {
      type: Number,
      default: 0,
    },
    maxLong: {
      type: Number,
      default: undefined,
    },
  },

  data() {
    return {
      wrapStyle: {},
      style: {},
    }
  },

  methods: {
    imgLoaded() {
      const img = this.$refs.imgRef
      const wrapStyle = {}
      const style = {}

      if (this.maxLong) {
        const longSide = img.width > img.height ? 'width' : 'height'
        const shortSide = longSide === 'width' ? 'height' : 'width'

        if (img[longSide] > this.maxLong) {
          img[shortSide] = (this.maxLong / img[longSide]) * img[shortSide]
          img[longSide] = this.maxLong
        }
      }

      if (this.rotate && this.rotate % 90 === 0) {
        const translateOffset = (img.width - img.height) / 2
        const rotateTrans = this.rotate < 0 ? 360 + this.rotate : this.rotate

        let offsetX = 0
        let offsetY = 0

        switch (rotateTrans) {
          case 90:
            offsetX = translateOffset
            offsetY = translateOffset
            wrapStyle.width = `${img.height}px`
            wrapStyle.height = `${img.width}px`

            break
          case 180:
            break
          case 270:
            offsetX = -translateOffset
            offsetY = -translateOffset
            wrapStyle.width = `${img.height}px`
            wrapStyle.height = `${img.width}px`

            break
          default:
            break
        }

        style.transform = `rotate(${this.rotate}deg) translate(${offsetX}px, ${offsetY}px)`
      }

      this.wrapStyle = wrapStyle
      this.style = style
    },
  },
}
