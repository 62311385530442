import { render, staticRenderFns } from "./select.vue?vue&type=template&id=3601dd4e&"
import script from "./select.vue?vue&type=script&lang=js&"
export * from "./select.vue?vue&type=script&lang=js&"
import style0 from "./select.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Option: require('/home/runner/work/myody_front/myody_front/components/option/Option.vue').default,Select: require('/home/runner/work/myody_front/myody_front/components/select/Select.vue').default,FormItem: require('/home/runner/work/myody_front/myody_front/components/form/FormItem.vue').default,Button: require('/home/runner/work/myody_front/myody_front/components/button/Button.vue').default,Form: require('/home/runner/work/myody_front/myody_front/components/form/Form.vue').default,Card: require('/home/runner/work/myody_front/myody_front/components/card/Card.vue').default})
