import { render, staticRenderFns } from "./index.vue?vue&type=template&id=595708ea&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Option: require('/home/runner/work/myody_front/myody_front/components/option/Option.vue').default,Select: require('/home/runner/work/myody_front/myody_front/components/select/Select.vue').default,FormItem: require('/home/runner/work/myody_front/myody_front/components/form/FormItem.vue').default,DateYearPager: require('/home/runner/work/myody_front/myody_front/components/date/DateYearPager.vue').default,Form: require('/home/runner/work/myody_front/myody_front/components/form/Form.vue').default,ChartDelivery: require('/home/runner/work/myody_front/myody_front/components/chart/ChartDelivery.vue').default,ChartCollection: require('/home/runner/work/myody_front/myody_front/components/chart/ChartCollection.vue').default,TableReportYear: require('/home/runner/work/myody_front/myody_front/components/table/TableReportYear.vue').default,ChartDownload: require('/home/runner/work/myody_front/myody_front/components/chart/ChartDownload.vue').default,Card: require('/home/runner/work/myody_front/myody_front/components/card/Card.vue').default})
