//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Table, TableColumn } from 'element-ui'

export default {
  components: {
    ElTable: Table,
    tableColumn: TableColumn,
  },

  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
}
