//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    imgUrl: {
      type: String,
      default: '',
    },
    intro: {
      type: String,
      default: '',
    },
    downloadOptions: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      downloadParams: this.value,
    }
  },

  watch: {
    value(newValue) {
      this.downloadParams = newValue
    },
  },
}
