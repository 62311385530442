//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'NewsDetailPage',

  layout: 'page',

  data() {
    return {
      date: '',
      title: '',
      content: null,
      floors: [],
      isShowAllFloors: false,
    }
  },

  mounted() {
    const { id } = this.$route.query

    this.fetchDetail(id)
  },

  methods: {
    async fetchDetail(id) {
      const res = await this.$axios.$get('/news/detail', {
        params: {
          id,
        },
      })

      const { date, title, content, floors } = res.data

      this.date = date
      this.title = title
      this.content = content
      this.floors = floors
    },
    showAllFloors() {
      this.isShowAllFloors = !this.isShowAllFloors
    },
  },
}
