var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$auth.loggedIn)?_c('div',{staticClass:"side-nav-wrap"},[_c('el-scrollbar',{class:{ collapse: _vm.isCollapse }},[_c('el-menu',{staticClass:"side-nav",attrs:{"default-active":_vm.$route.fullPath,"background-color":"#222","active-text-color":"#fff","text-color":"#b5beb5","collapse":_vm.isCollapse,"router":""}},[_c('el-menu-item',{attrs:{"index":"/"}},[_c('img',{staticClass:"nav-icon",attrs:{"src":require("assets/img/icon-home.png"),"alt":""}}),_vm._v(" "),_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v("ホーム")])]),_vm._v(" "),(_vm.checkShow(_vm.planYasai))?[_c('el-menu-item',{staticClass:"item-group",class:{ 'hide-first': !_vm.planYasai.calendar.display },attrs:{"index":_vm.planYasai.calendar.showFloorPage
              ? '/calendar/select'
              : '/calendar'}},[_c('img',{staticClass:"nav-icon",attrs:{"src":require("assets/img/icon-calendar.png"),"alt":""}}),_vm._v(" "),_c('span',{attrs:{"slot":"title"},slot:"title"},[_c('span',{staticClass:"group-title"},[_vm._v("オフィスでやさい（冷蔵プラン）")]),_vm._v(" "),_c('span',[_vm._v("お届けカレンダー")])])]),_vm._v(" "),(_vm.planYasai.request.display)?_c('el-menu-item',{attrs:{"index":_vm.planYasai.request.showFloorPage ? '/ody/select' : '/ody/request'}},[_c('img',{staticClass:"nav-icon",attrs:{"src":require("assets/img/icon-box.png"),"alt":""}}),_vm._v(" "),_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v("リクエスト")])]):_vm._e(),_vm._v(" "),(_vm.planYasai.history.display)?_c('el-menu-item',{attrs:{"index":_vm.planYasai.history.showFloorPage
              ? '/ody/history/select'
              : '/ody/history'}},[_c('img',{staticClass:"nav-icon",attrs:{"src":require("assets/img/icon-bag.png"),"alt":""}}),_vm._v(" "),_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v("注文履歴")])]):_vm._e(),_vm._v(" "),(_vm.planYasai.option_request.display)?_c('el-menu-item',{attrs:{"index":_vm.planYasai.option_request.showFloorPage
              ? '/option/select'
              : '/option/request'}},[_c('img',{staticClass:"nav-icon nav-icon-drink",attrs:{"src":require("assets/img/icon-juice.png"),"alt":""}}),_vm._v(" "),_c('span',{attrs:{"slot":"title"},slot:"title"},[_c('span',[_vm._v("定期お届けオプション")])])]):_vm._e(),_vm._v(" "),(_vm.planYasai.option_history.display)?_c('el-menu-item',{attrs:{"index":_vm.planYasai.option_history.showFloorPage
              ? '/option/history/select'
              : '/option/history'}},[_c('img',{staticClass:"nav-icon",attrs:{"src":require("assets/img/icon-bag.png"),"alt":""}}),_vm._v(" "),_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v("オプション注文履歴")])]):_vm._e()]:_vm._e(),_vm._v(" "),(_vm.checkShow(_vm.planGohan))?[_c('el-menu-item',{staticClass:"item-group",class:{ 'hide-first': !_vm.planGohan.calendar.display },attrs:{"index":_vm.planGohan.calendar.showFloorPage
              ? '/odg/calendar/select'
              : '/odg/calendar'}},[_c('img',{staticClass:"nav-icon",attrs:{"src":require("assets/img/icon-calendar.png"),"alt":""}}),_vm._v(" "),_c('span',{attrs:{"slot":"title"},slot:"title"},[_c('span',{staticClass:"group-title"},[_vm._v("オフィスでごはん（冷凍プラン）")]),_vm._v(" "),_c('span',[_vm._v("お届けカレンダー")])])]),_vm._v(" "),(_vm.planGohan.request.display)?_c('el-menu-item',{attrs:{"index":_vm.planGohan.request.showFloorPage ? '/odg/select' : '/odg/request'}},[_c('img',{staticClass:"nav-icon",attrs:{"src":require("assets/img/icon-box.png"),"alt":""}}),_vm._v(" "),_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v("リクエスト")])]):_vm._e(),_vm._v(" "),(_vm.planGohan.stock.display)?_c('el-menu-item',{attrs:{"index":_vm.planGohan.stock.showFloorPage ? '/odg/stock/select' : '/odg/stock'}},[_c('img',{staticClass:"nav-icon",attrs:{"src":require("assets/img/icon-box.png"),"alt":""}}),_vm._v(" "),_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v("在庫状況")])]):_vm._e(),_vm._v(" "),(_vm.planGohan.addon_request.display)?_c('el-menu-item',{attrs:{"index":_vm.planGohan.addon_request.showFloorPage
              ? '/odg/select?isAddon=true'
              : '/odg/addon'}},[_c('img',{staticClass:"nav-icon",attrs:{"src":require("assets/img/icon-cart.png"),"alt":""}}),_vm._v(" "),_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v("追加注文")])]):_vm._e(),_vm._v(" "),(_vm.planGohan.history.display)?_c('el-menu-item',{attrs:{"index":_vm.planGohan.history.showFloorPage
              ? '/odg/history/select'
              : '/odg/history'}},[_c('img',{staticClass:"nav-icon",attrs:{"src":require("assets/img/icon-bag.png"),"alt":""}}),_vm._v(" "),_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v("注文履歴")])]):_vm._e()]:_vm._e(),_vm._v(" "),(_vm.checkShow(_vm.planRemote))?[_c('el-menu-item',{staticClass:"item-group",attrs:{"index":"/remote/list"}},[_c('img',{staticClass:"nav-icon",attrs:{"src":require("assets/img/icon-user.png"),"alt":""}}),_vm._v(" "),_c('span',{attrs:{"slot":"title"},slot:"title"},[_c('span',{staticClass:"group-title"},[_vm._v("forリモート")]),_vm._v(" "),_c('span',[_vm._v("登録従業員一覧")])])]),_vm._v(" "),_c('el-menu-item',{attrs:{"index":"/remote/history"}},[_c('img',{staticClass:"nav-icon",attrs:{"src":require("assets/img/icon-database.png"),"alt":""}}),_vm._v(" "),_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v("ポイント付与履歴")])])]:_vm._e(),_vm._v(" "),(_vm.checkShow(_vm.planEquipment))?[_c(_vm.planEquipment.request.url ? 'li' : 'el-menu-item',{tag:"component",staticClass:"el-menu-item item-group",class:{
            'hide-first': !_vm.planEquipment.request.display,
            'custom-item': _vm.planEquipment.request.url,
          },attrs:{"index":_vm.planEquipment.request.showFloorPage
              ? '/cutlery/select'
              : '/odg/cutlery'},on:{"click":function($event){return _vm.linkTo(_vm.planEquipment.request.url)}}},[_c('img',{staticClass:"nav-icon",attrs:{"src":require("assets/img/icon-fork.png"),"alt":""}}),_vm._v(" "),_c('span',{attrs:{"slot":"title"},slot:"title"},[_c('span',{staticClass:"group-title"},[_vm._v("備品")]),_vm._v(" "),_c('span',[_vm._v("備品注文")])])]),_vm._v(" "),(_vm.planEquipment.history.display)?_c('el-menu-item',{attrs:{"index":_vm.planEquipment.history.showFloorPage
              ? '/cutlery/history/select'
              : '/cutlery/history'}},[_c('img',{staticClass:"nav-icon",attrs:{"src":require("assets/img/icon-bag.png"),"alt":""}}),_vm._v(" "),_c('span',{attrs:{"slot":"title"},slot:"title"},[_c('span',[_vm._v("注文履歴")])])]):_vm._e()]:_vm._e(),_vm._v(" "),(_vm.checkShow(_vm.planDonwload))?[_c('el-submenu',{staticClass:"item-group",attrs:{"index":"/download"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("ダウンロード")])]),_vm._v(" "),_vm._l((_vm.downloadList),function(item,index){return _c('el-menu-item',{key:index,attrs:{"index":("/download/" + (item.id))}},[_c('img',{staticClass:"nav-icon",attrs:{"src":require("assets/img/icon-download.png"),"alt":""}}),_vm._v(" "),_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v(_vm._s(item.name))])])})],2)]:_vm._e(),_vm._v(" "),_c('li',{staticClass:"menu-item",on:{"click":function($event){_vm.isCollapse = !_vm.isCollapse}}},[_c('img',{staticClass:"nav-icon nav-icon-toogle",class:{ close: _vm.isCollapse },attrs:{"src":require("assets/img/icon-circle-left.png"),"alt":""}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.isCollapse ? 'メニューを開く' : 'メニューを閉じる'))])])],2)],1),_vm._v(" "),_c('el-menu',{staticClass:"side-nav-shadow",attrs:{"collapse":_vm.isCollapse}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }