//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { formatDate } from '@/plugins/filters'

export default {
  name: 'OdgCalendarPage',

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from
    })
  },

  layout: 'page',

  data() {
    const { query } = this.$route

    return {
      floor: { id: query.floor },
      now: new Date(),
      date: new Date(),
      calendarData: [],
      holidays: [],
      statuses: [],
      submitLoading: false,
    }
  },

  computed: {
    month() {
      return formatDate(this.date, 'yyyy-MM')
    },
    downloadQuerys() {
      return this.downloadParams.map((item) => `${item}=1`).join('&')
    },
    statusMap() {
      const map = {}

      this.statuses.forEach((item) => {
        map[item.id] = item
      })

      return map
    },
  },

  watch: {
    month() {
      this.fetchCalendar()
    },
  },

  mounted() {
    if (this.prevRoute.name === 'calendar-delivery-confirm') {
      this.date = this.$store.state.calendarDate
    }

    this.fetchCalendar()
  },

  methods: {
    async fetchCalendar() {
      const res = await this.$axios.$get('/frozen/calendar/info', {
        params: {
          month: this.month,
          cid: this.floor.id,
        },
      })

      const { calendar, holidays, floor, images, statuses } = res.data

      this.floor = floor
      this.calendarData = calendar
      this.holidays = holidays
      this.downloadImgs = images
      this.statuses = statuses
    },
    onSelectDate(value, data) {
      if (data && data.can_click) {
        this.$store.commit('updateCalendarDate', value)

        this.$router.push({
          path: '/odg/calendar/delivery-confirm',
          query: {
            date: formatDate(value, 'yyyy-MM-dd'),
            floor: this.floor.id,
            floorName: this.floor.name,
          },
        })
      }
    },
    getDateContent(data) {
      if (
        data.delivery_number ||
        data.collection_money ||
        data.collection_number
      ) {
        return 'Tooltip'
      }

      return 'div'
    },
  },
}
