import { render, staticRenderFns } from "./download.vue?vue&type=template&id=7673b6ee&"
import script from "./download.vue?vue&type=script&lang=js&"
export * from "./download.vue?vue&type=script&lang=js&"
import style0 from "./download.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RadioButton: require('/home/runner/work/myody_front/myody_front/components/radio/RadioButton.vue').default,RadioGroup: require('/home/runner/work/myody_front/myody_front/components/radio/RadioGroup.vue').default,Card: require('/home/runner/work/myody_front/myody_front/components/card/Card.vue').default})
