//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { outputPdf } from '@/plugins/utils'
import { formatDate } from '@/plugins/filters'

const colors = {
  tags: {
    1: '#00aa64',
    2: '#0033ff',
    3: '#ff9900',
  },
}

export default {
  name: 'OdgCalendarDeliveryConfirmPage',

  layout: 'page',

  data() {
    const { query } = this.$route

    return {
      date: query.date,
      floor: query.floor,
      floorName: query.floorName,
      collectionAmount: null,
      photos: [],
      options: [],
      goodsCat: null,
      goodsList: [],
      pdfLoading: false,
    }
  },

  computed: {
    pageTitle() {
      return `${formatDate(this.date, 'M月d日(EEEEE)')}のお届け情報${
        this.floorName ? `(${this.floorName})` : ''
      }`
    },
  },

  mounted() {
    // set options first as default
    this.goodsCat = this.options[0]?.id

    this.fetchGoods()
  },

  methods: {
    async fetchGoods() {
      const res = await this.$axios.$get('/frozen/calendar/goods', {
        params: {
          date: this.date,
          cid: this.floor,
          goodsCat: this.goodsCat,
        },
      })

      const { list } = res.data

      this.goodsList = list.map((item) => {
        if (item.tags) {
          item.tags = item.tags.map((tag) => {
            tag.color = colors.tags[tag.id] || ''

            return tag
          })
        }

        return item
      })
    },
    async downloadPdf() {
      const $dom = document.getElementById('goods-list')
      const dom = $dom.cloneNode(true)
      const $pdfWrap = document.getElementById('goods-list-pdf-wrap')

      dom
        .querySelectorAll('.el-table__header thead > tr th:nth-child(3)')
        .forEach((el) => {
          el.style.display = 'none'
        })

      dom
        .querySelectorAll('.el-table__body tbody > tr td:nth-child(3)')
        .forEach((el) => {
          el.style.display = 'none'
        })

      dom
        .querySelectorAll('.el-table__body tbody > tr td:nth-child(1) img')
        .forEach((el) => {
          el.style.width = '60px'
          el.style.height = '60px'
        })

      dom
        .querySelectorAll('.el-table__body .goods-info .el-tag')
        .forEach((el) => {
          el.style.display = 'none'
        })

      $pdfWrap.innerHTML = `<div class="pdf-title">${this.pageTitle}</div>`

      dom.id = 'goods-list-pdf'

      $pdfWrap.append(dom)

      this.pdfLoading = true

      await outputPdf($pdfWrap, `${this.pageTitle}.pdf`)

      this.pdfLoading = false

      $pdfWrap.replaceChildren?.()
    },
  },
}
