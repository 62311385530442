import { render, staticRenderFns } from "./GoodsOdgItem.vue?vue&type=template&id=52fc8787&"
import script from "./GoodsOdgItem.vue?vue&type=script&lang=js&"
export * from "./GoodsOdgItem.vue?vue&type=script&lang=js&"
import style0 from "./GoodsOdgItem.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/home/runner/work/myody_front/myody_front/components/button/Button.vue').default,InputNumber: require('/home/runner/work/myody_front/myody_front/components/input/InputNumber.vue').default,GoodsBaseItem: require('/home/runner/work/myody_front/myody_front/components/goods/GoodsBaseItem.vue').default})
