//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    selectChange(item, value) {
      item.isSelected = value

      this.$emit('change', item)
    },
  },
}
