import { render, staticRenderFns } from "./new.vue?vue&type=template&id=5dd68601&"
import script from "./new.vue?vue&type=script&lang=js&"
export * from "./new.vue?vue&type=script&lang=js&"
import style0 from "./new.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Input: require('/home/runner/work/myody_front/myody_front/components/input/Input.vue').default,FormItem: require('/home/runner/work/myody_front/myody_front/components/form/FormItem.vue').default,Button: require('/home/runner/work/myody_front/myody_front/components/button/Button.vue').default,Form: require('/home/runner/work/myody_front/myody_front/components/form/Form.vue').default,PageLogin: require('/home/runner/work/myody_front/myody_front/components/page/PageLogin.vue').default})
