//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    isText: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    linkTo() {
      if (!this.$attrs.to) {
        return '/'
      }

      // improve: change url to site route
      return this.$attrs.to.replace(this.$store.state.siteURL, '')
    },
    componentWrap() {
      if (this.disabled || !this.$attrs.to) {
        return 'span'
      }

      return this.linkTo.includes('http') ? 'a' : 'n-link'
    },
  },
}
