import { render, staticRenderFns } from "./GoodsOdyItem.vue?vue&type=template&id=71b8cbfc&"
import script from "./GoodsOdyItem.vue?vue&type=script&lang=js&"
export * from "./GoodsOdyItem.vue?vue&type=script&lang=js&"
import style0 from "./GoodsOdyItem.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Tag: require('/home/runner/work/myody_front/myody_front/components/tag/Tag.vue').default,ListLabel: require('/home/runner/work/myody_front/myody_front/components/list/ListLabel.vue').default,InputNumber: require('/home/runner/work/myody_front/myody_front/components/input/InputNumber.vue').default,ListLabelItem: require('/home/runner/work/myody_front/myody_front/components/list/ListLabelItem.vue').default,Button: require('/home/runner/work/myody_front/myody_front/components/button/Button.vue').default,GoodsBaseItem: require('/home/runner/work/myody_front/myody_front/components/goods/GoodsBaseItem.vue').default})
