import { render, staticRenderFns } from "./detail.vue?vue&type=template&id=d65c8c6c&"
import script from "./detail.vue?vue&type=script&lang=js&"
export * from "./detail.vue?vue&type=script&lang=js&"
import style0 from "./detail.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Option: require('/home/runner/work/myody_front/myody_front/components/option/Option.vue').default,Select: require('/home/runner/work/myody_front/myody_front/components/select/Select.vue').default,FormItem: require('/home/runner/work/myody_front/myody_front/components/form/FormItem.vue').default,Form: require('/home/runner/work/myody_front/myody_front/components/form/Form.vue').default,TableMonthSummary: require('/home/runner/work/myody_front/myody_front/components/table/TableMonthSummary.vue').default,TableMonthCat: require('/home/runner/work/myody_front/myody_front/components/table/TableMonthCat.vue').default,Col: require('/home/runner/work/myody_front/myody_front/components/col/Col.vue').default,ChartCat: require('/home/runner/work/myody_front/myody_front/components/chart/ChartCat.vue').default,Row: require('/home/runner/work/myody_front/myody_front/components/row/Row.vue').default,ChartPay: require('/home/runner/work/myody_front/myody_front/components/chart/ChartPay.vue').default,RadioOptions: require('/home/runner/work/myody_front/myody_front/components/radio/RadioOptions.vue').default,TableCatSummary: require('/home/runner/work/myody_front/myody_front/components/table/TableCatSummary.vue').default,Card: require('/home/runner/work/myody_front/myody_front/components/card/Card.vue').default})
