//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { formatDate, currency } from '@/plugins/filters'

export default {
  name: 'CutleryHistoryDetailPage',

  layout: 'page',

  data() {
    return {
      listLoading: false,
      history: null,
      equipmentList: [],
      summary: [],
      downloadUrl: '',
      isCancelable: false,
      cancelLoading: false,
    }
  },

  mounted() {
    this.fetchList()
  },

  methods: {
    async fetchList() {
      const { id } = this.$route.params

      this.listLoading = true

      const res = await this.$axios.$get(`/equipments/history/${id}`)

      this.listLoading = false

      const {
        history,
        equipmentList,
        buyDate,
        priceTotal,
        downloadUrl,
        isCancelable,
      } = res.data

      this.history = history
      this.downloadUrl = downloadUrl
      this.isCancelable = isCancelable
      this.equipmentList = equipmentList.map((item) => {
        item.name = item.equipmentName

        return item
      })

      this.summary = [
        {
          name: '注文日',
          text: `${formatDate(buyDate, 'yyyy/MM/dd')}`,
          full: true,
        },
        {
          name: '商品代合計',
          text: `${currency(priceTotal)}（税別）`,
        },
      ]
    },
    async postCancel() {
      try {
        await this.$confirm(
          'この注文をキャンセルしますか？',
          '備品注文　キャンセル確認画面',
          {
            confirmButtonText: 'キャンセルする',
          }
        )
      } catch (error) {
        return
      }

      const { id } = this.$route.params

      this.cancelLoading = true

      const res = await this.$axios.post(`/equipments/cancel/${id}`)

      this.cancelLoading = false

      if (res.status !== 200) {
        return
      }

      this.$toast({
        message: '配達がキャンセルされました',
      })

      this.$router.back()
    },
  },
}
