import { render, staticRenderFns } from "./history.vue?vue&type=template&id=7589281f&"
import script from "./history.vue?vue&type=script&lang=js&"
export * from "./history.vue?vue&type=script&lang=js&"
import style0 from "./history.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DateMonthPager: require('/home/runner/work/myody_front/myody_front/components/date/DateMonthPager.vue').default,FormItem: require('/home/runner/work/myody_front/myody_front/components/form/FormItem.vue').default,Form: require('/home/runner/work/myody_front/myody_front/components/form/Form.vue').default,TableRemoteHistory: require('/home/runner/work/myody_front/myody_front/components/table/TableRemoteHistory.vue').default,Pagination: require('/home/runner/work/myody_front/myody_front/components/pagination/Pagination.vue').default,Card: require('/home/runner/work/myody_front/myody_front/components/card/Card.vue').default})
