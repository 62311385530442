//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ReportDetailPage',

  layout: 'page',

  data() {
    const { year, month } = this.$route.query

    return {
      floor: null,
      floors: null,
      year,
      month,
      listLoading: false,
      summaryData: [],
      catData: [],
      catChart: {},
      payChart: {},
      options: [],
      goodsCat: null,
      catList: [],
      catListMap: [],
    }
  },

  computed: {
    goodsList() {
      return this.catListMap[this.goodsCat]
    },
  },

  mounted() {
    this.fetchList()
  },

  methods: {
    async fetchList() {
      this.listLoading = true

      const res = await this.$axios.$get('/report/month', {
        parmas: {
          year: this.year,
          month: this.month,
          floor: this.floor,
        },
      })

      this.listLoading = false

      const { summary, catChart, payChart, catList, floors } = res.data

      if (floors && !this.floors) {
        this.floors = floors
        this.floor = floors[0]
      }

      this.summaryData = [summary]
      this.catChart = catChart || {}
      this.payChart = payChart || {}

      const {
        dataset: { source: catSource },
      } = catChart

      const catData = []

      for (let i = 1; i < catSource.length; i++) {
        const item = catSource[i]

        catData.push({
          catName: item[0],
          deliveryCount: item[1],
          recycleCount: item[2],
          utilization: item[3],
        })
      }

      this.catData = catData

      const options = []
      const catListMap = {}

      catList.map((item) => {
        options.push({
          id: item.catID,
          label: item.catName,
        })

        catListMap[item.catID] = item.goodsList.map((goods) => {
          goods.catName = item.catName
          goods.cover = goods.cover?.url

          if (!goods.count) {
            goods.count = 0
          }

          return goods
        })

        return item
      })

      this.catList = catList.slice()
      this.options = options
      this.catListMap = catListMap

      // set options first as default
      this.goodsCat = this.options[0]?.id
    },
  },
}
