//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'CompanyInfoPage',

  layout: 'page',

  data() {
    const { contactType, content } = this.$route.query

    return {
      name: '',
      owner: '',
      email: '',
      contactType,
      contactTypeOptions: [],
      content: content || '',
      formLoading: false,
    }
  },

  mounted() {
    this.fetchOptions()
  },

  methods: {
    async fetchOptions() {
      this.formLoading = true

      const res = await this.$axios.$get('/contact/options')

      this.formLoading = false

      const { companyInfo, options } = res.data

      this.name = companyInfo.name
      this.owner = companyInfo.owner
      this.email = companyInfo.email
      this.contactTypeOptions = options
    },
    async save() {
      const form = {
        name: this.name,
        owner: this.owner,
        email: this.email,
        contactType: this.contactType,
        content: this.content,
      }

      if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,6}$/.test(form.email)) {
        return this.$toast({
          message: '確認してください ご担当者様メールアドレス',
        })
      }

      this.formLoading = true

      const res = await this.$axios.post('/contact', form)

      this.formLoading = false

      if (res.status !== 200) {
        return
      }

      this.$toast({
        message: '保存しました',
      })

      this.$router.push('/contact/complete')
    },
  },
}
