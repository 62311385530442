//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'MobileIndex',

  layout: 'mobile',

  data() {
    return {
      listAd: [
        {
          id: 1,
          imgUrl: 'img/mobile/ad1.png',
          link: '/',
        },
        {
          id: 2,
          imgUrl: 'img/mobile/ad1.png',
          link: '/',
        },
        {
          id: 3,
          imgUrl: 'img/mobile/ad1.png',
          link: '/',
        },
      ],
    }
  },

  head() {
    return {
      bodyAttrs: {
        class: this.$options.name,
      },
    }
  },
}
