//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { addMonths, isBefore, differenceInMonths } from 'date-fns'
import { formatDate } from '@/plugins/filters'

export default {
  name: 'DownloadDeliveryPage',

  layout: 'page',

  data() {
    return {
      pageLoading: false,
      formLoading: false,
      submitLoading: false,
      floor: null,
      floors: [],
      plan: null,
      plans: [],
      plansMap: {},
      startMonth: addMonths(new Date(), -1),
      endMonth: new Date(),
      pickerOptions: {},
      showFloorsSet: null,
      tips: '',
    }
  },

  computed: {
    formDisabled() {
      return this.formLoading || this.submitLoading
    },
    showFloors() {
      return this.showFloorsSet ?? this.floors?.length > 1
    },
    downloadUrl() {
      const form = {
        plan: this.plan,
        from: formatDate(this.startMonth, 'yyyy-MM'),
        to: formatDate(this.endMonth, 'yyyy-MM'),
      }

      if (this.showFloors) {
        form.id = this.floor
      }

      const query = Object.keys(form)
        .map((key) => {
          return `${key}=${form[key]}`
        })
        .join('&')

      return `/download/delivery/request?${query}`
    },
    allowSubmit() {
      if (
        this.formLoading ||
        isBefore(this.endMonth, this.startMonth) ||
        differenceInMonths(this.endMonth, this.startMonth) > 11
      ) {
        return false
      }

      return true
    },
  },

  watch: {
    plan(newValue) {
      if (!newValue) {
        return
      }

      const planItem = this.plans.find((item) => newValue === item.id)
      const { startMonth, endMonth } = planItem

      if (startMonth || endMonth) {
        this.$set(this.pickerOptions, 'disabledDate', (time) => {
          return (
            isBefore(time, new Date(startMonth)) ||
            isBefore(new Date(endMonth), time)
          )
        })
      } else {
        this.$set(this.pickerOptions, 'disabledDate', {})
      }

      this.startMonth = new Date(endMonth)
      this.endMonth = new Date(endMonth)
    },
  },

  mounted() {
    this.fetchOptions(false)
  },

  methods: {
    async fetchOptions() {
      this.formLoading = true

      const res = await this.$axios.$get('/download/delivery/options', {
        params: {
          id: this.floor,
        },
      })

      this.formLoading = false

      const { floors, show_floor_name: showFloorName, tips } = res.data

      this.floors = floors.map(({ id, name }) => ({ id, name }))
      this.floor = this.floors[0].id
      this.showFloorsSet = showFloorName
      this.tips = tips

      const plans = {}

      floors.map((item) => {
        const planAllItem = {
          id: 'all',
          name: '全てのプラン',
        }

        const endMonthTs = Math.max(
          ...item.plans.map((plan) => Date.parse(new Date(plan.endMonth)))
        )

        planAllItem.endMonth = new Date(endMonthTs)

        item.plans.unshift(planAllItem)

        plans[item.id] = item.plans

        return item
      })

      this.plansMap = plans
      this.floorChange()

      // init month
      this.startMonth = new Date(this.plansMap[this.floor].endMonth)
      this.endMonth = new Date(this.plansMap[this.floor].endMonth)
    },
    floorChange() {
      this.plans = this.plansMap[this.floor]
      this.plan = this.plans[0].id
    },
    async save() {
      this.submitLoading = true

      await this.$download(this.downloadUrl)

      this.submitLoading = false
    },
  },
}
