//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { addMonths } from 'date-fns'
import { formatDate } from '@/plugins/filters'

export default {
  name: 'OdoRequestPage',

  layout: 'page',

  data() {
    return {
      isEdit: false,
      listLoading: false,
      submitLoading: false,
      floor: null,
      floors: null,
      step: 1,
      date: addMonths(new Date(), 1),
      dateMax: addMonths(new Date(), 1),
      deadline: null,
      monthDeliveryCount: 0,
      priceTotal: 0,
      employeesPriceTotal: 0,
      billingAmount: 0,
      goodsList: [],
      goodsSelectedID: null,
      deliveryDate: null,
    }
  },

  computed: {
    month() {
      return formatDate(this.date, 'yyyy-MM')
    },
    deliveryTotal() {
      if (!this.goodsSelectedID) {
        return 0
      }

      return this.goodsSelected.count * this.monthDeliveryCount
    },
    goodsSelected() {
      if (!this.goodsSelectedID) {
        return {}
      }

      return this.goodsList.find((item) => item.id === this.goodsSelectedID)
    },
    goodsSelectedText() {
      return this.goodsSelectedID ? this.goodsSelected.goodsTitle : 'なし'
    },
    allowSubmit() {
      return this.deliveryTotal > 0
    },
  },

  watch: {
    goodsSelected(newValue) {
      this.priceTotal = newValue.price
      this.employeesPriceTotal = newValue.employeesPrice
      this.billingAmount = this.priceTotal - this.employeesPriceTotal
    },
  },

  mounted() {
    this.fetchOptions()
  },

  methods: {
    async fetchOptions() {
      this.listLoading = true

      const res = await this.$axios.$get('/odo/options', {
        params: {
          month: this.month,
          floor: this.floor,
        },
      })

      this.listLoading = false

      const {
        floors,
        deadline,
        goodsList,
        goodsSelectedID,
        monthDeliveryCount,
        deliveryDate,
      } = res.data

      if (floors && !this.floors) {
        this.floors = floors
        this.floor = floors[0]
      }

      this.goodsList = goodsList.map((item) => {
        item.cover = item.cover?.url

        if (goodsSelectedID === item.id) {
          item.isSelected = true
        }

        return item
      })

      this.goodsSelectedID = goodsSelectedID
      this.monthDeliveryCount = monthDeliveryCount
      this.deadline = deadline
      this.deliveryDate = deliveryDate
    },
    editChange() {
      if (!this.goodsSelectedID) {
        this.goodsSelectedID = this.goodsList[0].id
        this.goodsList[0].isSelected = true
      }

      this.isEdit = true
    },
    selectChange(goods) {
      this.goodsList = this.goodsList.map((item) => {
        if (goods.id !== item.id) {
          item.isSelected = false
        } else {
          item.isSelected = true
        }

        return item
      })

      this.goodsSelectedID = goods.id
    },
    async submit() {
      this.submitLoading = true

      const form = {
        month: this.month,
        floor: this.floor,
        goods: this.goodsSelected,
        priceTotal: this.priceTotal,
        employeesPriceTotal: this.employeesPriceTotal,
        billingAmount: this.billingAmount,
      }

      const res = await this.$axios.post('/odo/requests', form)

      this.submitLoading = false

      if (res.status !== 200) {
        return
      }

      this.$toast({
        message: '保存しました',
      })

      this.$router.push('/odo/request-complete')
    },
  },
}
