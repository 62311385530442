//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'OdgHistoryProductPage',

  layout: 'page',

  data() {
    return {
      listLoading: false,
      history: null,
      catList: [],
      downloadUrl: '',
      isCancelable: false,
      cancelLoading: false,
    }
  },

  mounted() {
    this.fetchList()
  },

  methods: {
    async fetchList() {
      const { id } = this.$route.params

      this.listLoading = true

      const res = await this.$axios.$get(`/frozen/history/product/${id}`)

      this.listLoading = false

      const { history, catList, downloadUrl, isCancelable } = res.data

      this.history = history
      this.downloadUrl = downloadUrl
      this.isCancelable = isCancelable
      this.catList = catList.map((item) => {
        item.goodsList = item.goodsList.map((goods) => {
          goods.name = goods.goodsTitle
          goods.description = goods.goodsIntro

          return goods
        })

        item.title = `${item.catName}詳細`
        item.summary = [
          {
            name: 'お届けセット数',
            text: `${item.count}セット`,
          },
          {
            name: 'お届け個数',
            text: `${item.deliveryCount}こ`,
          },
        ]

        return item
      })
    },
    async postCancel() {
      try {
        await this.$confirm(
          'この注文をキャンセルしますか？',
          `${
            this.history.type === 'monthly' ? '定期' : '追加'
          }注文 キャンセル確認画面`,
          {
            confirmButtonText: 'キャンセルする',
          }
        )
      } catch (error) {
        return
      }

      const { id } = this.$route.params

      this.cancelLoading = true

      const res = await this.$axios.post(`/frozen/history/cancel/${id}`)

      this.cancelLoading = false

      if (res.status !== 200) {
        return
      }

      this.$toast({
        message: '配達がキャンセルされました',
      })

      this.$router.back()
    },
  },
}
