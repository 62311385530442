import { render, staticRenderFns } from "./StepsEnd.vue?vue&type=template&id=33e3ba4a&"
import script from "./StepsEnd.vue?vue&type=script&lang=js&"
export * from "./StepsEnd.vue?vue&type=script&lang=js&"
import style0 from "./StepsEnd.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/home/runner/work/myody_front/myody_front/components/button/Button.vue').default,Link: require('/home/runner/work/myody_front/myody_front/components/link/Link.vue').default})
