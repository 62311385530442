//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { isBefore } from 'date-fns'
import { formatDate } from '@/plugins/filters'

export default {
  name: 'OutagePage',

  layout: 'page',

  data() {
    return {
      stepActive: 0,
      steps: [
        {
          title: 'アカウント選択',
        },
        {
          title: '停止日選択',
        },
        {
          title: '配達方法と対応',
        },
        {
          title: '停止対応確認',
        },
      ],
      calendarData: [],
      holidays: [],
      accountOptions: [],
      account: null,
      deliveryModeOptions: [
        {
          label:
            '同週別曜日に振り替えてお届け<br /><span class="tips">※配達員お届けの場合:振り替えを希望される場合、宅急便でのお届けとなります</span><br class="sub-br" /><span class="tips">※北海道でご利用のお客様は、月曜日と水曜日のみ選択可能です</span>',
          id: 1,
        },
        {
          label: 'お届けをしない（月内の残りのお届け日で振り分け）',
          id: 2,
        },
      ],
      date: new Date(),
      selectRange: [],
      selectableDates: [],
      showDeliveryMode: false,

      deliveryMode: 1,
      deliveryDate: null,
      formLoading: false,
      tips: '',
      replacementMethods: [],
    }
  },

  computed: {
    headerTitle() {
      return this.steps[this.stepActive]?.title
    },
    curAccount() {
      return this.accountOptions.find((item) => item.id === this.account) ?? {}
    },
    isReplacement() {
      return this.curAccount?.requires_replacement_method
    },
    filterDeliveryModeOptions() {
      return this.deliveryModeOptions.filter((item) =>
        this.replacementMethods.includes(item.id)
      )
    },
    statusMap() {
      return this.calendarData.reduce((acc, cur) => {
        if (!acc[cur.status]) {
          acc[cur.status] = {
            name: cur.label,
            backgroundColor: cur.backgroundColor,
          }
        }

        return acc
      }, {})
    },
  },

  mounted() {
    this.fetchOptions()
  },

  methods: {
    async fetchOptions() {
      const res = await this.$axios.$get('/delivery/stop/accounts')

      if (!res.data) {
        return
      }

      const { accounts } = res.data

      this.accountOptions = accounts
    },
    async fetchDates() {
      const res = await this.$axios.$get(`/delivery/stop/${this.account}/dates`)

      const { calendar, holidays, startDate, tips } = res.data

      this.calendarData = calendar
      this.holidays = holidays
      this.tips = tips

      if (startDate) {
        const curStartDate = new Date(startDate)

        this.selectRange = [curStartDate]

        // update default select date
        if (isBefore(this.date, curStartDate)) {
          this.date = curStartDate
        }
      }
    },
    async fetchMethods() {
      const res = await this.$axios.$get(
        `/delivery/stop/${this.account}/dates/${formatDate(
          this.date,
          'yyyy-MM-dd'
        )}/replacement-methods`
      )

      const { methods } = res.data

      this.replacementMethods = methods
      this.deliveryMode = methods[0]
    },
    async fetchReplacementDates() {
      const res = await this.$axios.$get(
        `/delivery/stop/${this.account}/dates/${formatDate(
          this.date,
          'yyyy-MM-dd'
        )}/replacement-dates`
      )

      const { dates } = res.data

      const selectableDates = dates.map((item) => item.date)

      this.selectableDates = selectableDates

      const sortDates = selectableDates.slice().sort()

      this.deliveryDate = new Date(sortDates[0])
    },
    async doNextStep(step) {
      switch (step) {
        case 0:
          await this.fetchDates()

          this.stepActive = 1
          break

        case 1:
          if (!this.isReplacement) {
            await this.fetchReplacementDates()
          } else {
            await this.fetchMethods()
          }

          this.stepActive = 2
          break

        case 2:
          if (
            this.isReplacement &&
            this.deliveryMode === 1 &&
            !this.showDeliveryMode
          ) {
            this.showDeliveryMode = true
            await this.fetchReplacementDates()
          } else {
            this.stepActive = 3
          }
          break

        default:
          this.stepActive = step + 1
          break
      }
    },
    doPrevStep(step) {
      switch (step) {
        case 2:
          if (this.isReplacement && this.deliveryMode === 1) {
            this.showDeliveryMode = false
          } else {
            this.stepActive = 1
          }

          this.deliveryDate = null
          break
        default:
          this.stepActive = step - 1
          break
      }
    },
    async save() {
      const form = {
        accountID: this.account,
        stopDate: formatDate(this.date, 'yyyy-MM-dd'),
        replacementDate: formatDate(this.deliveryDate, 'yyyy-MM-dd'),
        replacementMethod: this.isReplacement ? this.deliveryMode : null,
      }

      this.formLoading = true

      const res = await this.$axios.post(
        `/delivery/stop/${this.account}/dates/${formatDate(
          this.date,
          'yyyy-MM-dd'
        )}/replacement-dates/select`,
        form
      )

      this.formLoading = false

      if (res.status !== 200) {
        return
      }

      this.$toast({
        message: '保存しました',
      })

      this.stepActive = 4
    },
  },
}
