//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Table, TableColumn } from 'element-ui'

export default {
  components: {
    ElTable: Table,
    tableColumn: TableColumn,
  },

  props: {
    data: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    summary: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: '',
    },
    deliveryCountTitle: {
      type: String,
      default: '月入荷数',
    },
  },

  data() {
    return {
      editList: null,
    }
  },

  methods: {
    showEditStock(id) {
      this.$emit('onShowEditStock', id)
    },
    saveStock(id, stock) {
      this.$emit('onStockChange', id, stock)
    },
  },
}
