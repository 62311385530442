//
//
//
//
//
//
//

export default {
  props: {
    name: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'default',
    },
    bold: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: null,
    },
    fontSize: {
      type: String,
      default: null,
    },
  },

  computed: {
    itemStyle() {
      const style = {}

      if (this.color) {
        style.color = this.color
      }

      if (this.fontSize) {
        style['font-size'] = this.fontSize
      }

      return style
    },
  },
}
