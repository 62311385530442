//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { formatDate } from '@/plugins/filters'

export default {
  name: 'OdgStockPage',

  layout: 'page',

  data() {
    const { query } = this.$route

    return {
      listLoading: false,
      floor: { id: query.floor },
      goodsList: [],
      deliveryCountTitle: '月入荷数',
      datetime: '',
      isFilterNone: false,
      tips: '',
      showFloorPage: false,
      showConsumptionPage: false,
      showAddonPage: false,
    }
  },

  computed: {
    month() {
      return formatDate(this.date, 'yyyy-MM')
    },
    priceLists() {
      return this.goodsList.reduce((acc, cur) => {
        if (this.isFilterNone && cur.stock < 1) {
          return acc
        }

        if (!acc[cur.employeesPrice]) {
          acc[cur.employeesPrice] = [cur]
        } else {
          acc[cur.employeesPrice].push(cur)
        }

        return acc
      }, {})
    },
    priceListTotalMap() {
      const map = {}

      Object.keys(this.priceLists).forEach((key) => {
        let total = 0

        this.priceLists[key].forEach((item) => {
          total += item.stock
        })

        map[key] = total
      })

      return map
    },
    stockTotal() {
      return Object.values(this.priceListTotalMap).reduce(
        (acc, cur) => acc + cur,
        0
      )
    },
    isNone() {
      return this.goodsList.length < 1
    },
  },

  mounted() {
    this.fetchList()
  },

  methods: {
    async fetchList() {
      this.listLoading = true

      const res = await this.$axios.$get('/frozen/stock', {
        params: {
          month: this.month,
          cid: this.floor.id,
        },
      })

      this.listLoading = false

      const {
        floor,
        goodsList,
        datetime,
        month,
        tips,
        showFloorPage,
        showConsumptionPage,
        showAddonPage,
      } = res.data

      this.floor = floor
      this.showFloorPage = showFloorPage
      this.showConsumptionPage = showConsumptionPage
      this.showAddonPage = showAddonPage
      this.tips = tips
      this.goodsList = goodsList.map((item) => ({
        ...item,
        loading: false,
        isEditStock: false,
        editStock: item.stock,
      }))
      this.datetime = datetime
      this.deliveryCountTitle = `${month.split('-')?.[1]}月入荷数`
    },
    onShowEditStock(id) {
      const goods = this.goodsList.find((item) => item.id === id)

      goods.isEditStock = true
    },
    async onStockChange(id, stock) {
      const goods = this.goodsList.find((item) => item.id === id)

      goods.loading = true

      const res = await this.$axios.post('/frozen/stock', {
        floorID: this.floor.id,
        stocks: {
          stockID: goods.stockID,
          stock,
        },
      })

      goods.loading = false

      if (res.status !== 200) {
        return
      }

      goods.stock = stock
      goods.isEditStock = false
    },
  },
}
