import { render, staticRenderFns } from "./GoodsOdoItem.vue?vue&type=template&id=8eb0a1e4&"
import script from "./GoodsOdoItem.vue?vue&type=script&lang=js&"
export * from "./GoodsOdoItem.vue?vue&type=script&lang=js&"
import style0 from "./GoodsOdoItem.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Radio: require('/home/runner/work/myody_front/myody_front/components/radio/Radio.vue').default,ListLabel: require('/home/runner/work/myody_front/myody_front/components/list/ListLabel.vue').default,GoodsBaseItem: require('/home/runner/work/myody_front/myody_front/components/goods/GoodsBaseItem.vue').default})
