//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { formatDate } from '@/plugins/filters'

export default {
  name: 'RemoteHistoryPage',

  layout: 'page',

  data() {
    const { query } = this.$route

    return {
      listLoading: false,
      date: new Date(),
      dateMax: new Date(),
      list: [],
      page: query.page || 1,
      total: 0,
      pageSize: 10,
    }
  },

  computed: {
    month() {
      return formatDate(this.date, 'yyyy-MM')
    },
    tableStartTitle() {
      return `${formatDate(this.date, 'yyyy年M月')}付与ポイント`
    },
  },

  mounted() {
    const { query } = this.$route
    const page = query?.page || 1

    this.fetchList(page)
  },

  methods: {
    async fetchList(page) {
      this.listLoading = true

      const res = await this.$axios.$get('/remote/history', {
        params: {
          month: this.month,
          page,
        },
      })

      this.listLoading = false

      const { list, total, pageSize } = res.data

      this.list = list
      this.total = total
      this.pageSize = pageSize
    },
    toMonth() {
      this.page = 1
      this.fetchList(this.page)
    },
    toPage(page) {
      this.fetchList(page)
    },
  },
}
