//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
    iconUrl: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      default: 'お申込みはこちら',
    },
    link: {
      type: String,
      default: '',
    },
  },
}
