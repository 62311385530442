import { render, staticRenderFns } from "./mobile.vue?vue&type=template&id=5bbbb846&"
import script from "./mobile.vue?vue&type=script&lang=js&"
export * from "./mobile.vue?vue&type=script&lang=js&"
import style0 from "./mobile.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MobileHeader: require('/home/runner/work/myody_front/myody_front/components/mobile/MobileHeader.vue').default,PageWrap: require('/home/runner/work/myody_front/myody_front/components/page/PageWrap.vue').default,Footer: require('/home/runner/work/myody_front/myody_front/components/Footer.vue').default,LayoutWrap: require('/home/runner/work/myody_front/myody_front/components/LayoutWrap.vue').default})
