//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Table, TableColumn } from 'element-ui'

export default {
  components: {
    ElTable: Table,
    tableColumn: TableColumn,
  },

  props: {
    list: {
      type: Array,
      default: () => [],
    },
    filter: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    fullMode() {
      return ['nutrients', 'goodsIntro'].every((item) =>
        this.filter.includes(item)
      )
    },
    goodsIntroColspan() {
      const col = ['price', 'employeesPrice'].reduce((acc, cur) => {
        if (this.filter.includes(cur)) {
          acc += 1
        }

        return acc
      }, 0)

      return [1, col]
    },
    renderList() {
      let newList = []

      if (this.fullMode) {
        this.list.forEach((item, index) => {
          newList = newList.concat([
            item,
            { goodsTitle: item.goodsIntro, fullMode: true },
          ])
        })

        return newList
      }

      return this.list
    },
  },

  methods: {
    renderSpan({ row, column, rowIndex, columnIndex }) {
      if (this.fullMode) {
        if (
          rowIndex % 2 === 0 &&
          ['goodsTitle', 'price', 'employeesPrice'].includes(column.property)
        ) {
          return [2, 1]
        }

        if (rowIndex % 2 === 1) {
          if (column.property === 'goodsTitle') {
            return [1, 7]
          }

          return [0, 0]
        }
      }
    },
  },
}
