import { render, staticRenderFns } from "./FormMenuDownload.vue?vue&type=template&id=013d64ea&"
import script from "./FormMenuDownload.vue?vue&type=script&lang=js&"
export * from "./FormMenuDownload.vue?vue&type=script&lang=js&"
import style0 from "./FormMenuDownload.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Option: require('/home/runner/work/myody_front/myody_front/components/option/Option.vue').default,Select: require('/home/runner/work/myody_front/myody_front/components/select/Select.vue').default,FormItem: require('/home/runner/work/myody_front/myody_front/components/form/FormItem.vue').default,DatePicker: require('/home/runner/work/myody_front/myody_front/components/date/DatePicker.vue').default,Checkbox: require('/home/runner/work/myody_front/myody_front/components/checkbox/Checkbox.vue').default,Button: require('/home/runner/work/myody_front/myody_front/components/button/Button.vue').default,Form: require('/home/runner/work/myody_front/myody_front/components/form/Form.vue').default,ListProductMenu: require('/home/runner/work/myody_front/myody_front/components/list/ListProductMenu.vue').default,ListProductRichTable: require('/home/runner/work/myody_front/myody_front/components/list/ListProductRichTable.vue').default,ListProductIntro: require('/home/runner/work/myody_front/myody_front/components/list/ListProductIntro.vue').default})
