//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    imgUrl: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '',
    },
  },

  computed: {
    mainStyle() {
      return {
        backgroundColor: this.color,
      }
    },
  },
}
