//
//
//
//

import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { PieChart } from 'echarts/charts'
import {
  TooltipComponent,
  GridComponent,
  LegendComponent,
  DatasetComponent,
} from 'echarts/components'
import VChart from 'vue-echarts'

use([
  TooltipComponent,
  GridComponent,
  LegendComponent,
  DatasetComponent,
  PieChart,
  CanvasRenderer,
])

export default {
  components: {
    VChart,
  },
  props: {
    dataset: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    const colors = [
      '#00aa64',
      '#c5a166',
      '#03b8df',
      '#c6291f',
      '#9f9f9f',
      '#99cc33',
    ]

    return {
      option: {
        color: colors,
        tooltip: {
          trigger: 'item',
          backgroundColor: '#5e5f5e',
          borderColor: '#5e5f5e',
          padding: [6, 8],
          textStyle: {
            color: '#fff',
            fontSize: 12,
          },
        },
        legend: {
          bottom: 0,
        },
        dataset: this.dataset,
        series: [
          {
            type: 'pie',
            avoidLabelOverlap: false,
            radius: ['30%', '70%'],
            label: {
              show: false,
              position: 'center',
            },
            top: -60,
          },
        ],
      },
    }
  },
  watch: {
    dataset(newValue) {
      this.option.dataset = newValue
    },
  },
}
