//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    cols: {
      type: Number,
      default: 1,
    },
    grid: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    gridStyle() {
      const style = []

      let colStart = 1
      let colEnd = 1
      let rowStart = 1
      let rowEnd = 1

      let nextColEnd = 0

      for (let i = 0; i < this.list.length; i++) {
        const item = this.list[i]

        colEnd = colStart + item.col
        rowEnd = rowStart + item.row

        style.push(
          `grid-column: ${colStart} / ${colEnd}; grid-row: ${rowStart}/ ${rowEnd};`
        )

        if (item.row === 2) {
          nextColEnd += 1
        }

        if (colEnd === 3) {
          colStart = nextColEnd + 1
          rowStart += 1
        } else {
          rowEnd = rowStart + 1
          colStart += 1
        }
      }

      return style
    },
    isRowSingle() {
      return this.list.length <= 2 && !this.list.some((item) => item.col >= 2)
    },
  },
}
