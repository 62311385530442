//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { addMonths, isBefore, differenceInMonths } from 'date-fns'
import { formatDate } from '@/plugins/filters'

export default {
  name: 'DownloadConsumptionPage',

  layout: 'page',

  data() {
    return {
      pageLoading: false,
      formLoading: false,
      submitLoading: false,
      floor: null,
      floors: [],
      floorsMap: null,
      startMonth: addMonths(new Date(), -1),
      endMonth: new Date(),
      pickerOptions: {},
      showFloorsSet: null,
      tips: '',
      dataTypeList: [],
      dataType: null,
    }
  },

  computed: {
    formDisabled() {
      return this.formLoading || this.submitLoading
    },
    showFloors() {
      return this.showFloorsSet ?? this.floors?.length > 1
    },
    downloadUrl() {
      const form = {
        from: formatDate(this.startMonth, 'yyyy-MM'),
        to: formatDate(this.endMonth, 'yyyy-MM'),
        dataType: this.dataType,
      }

      if (this.showFloors) {
        form.id = this.floor
      }

      const query = Object.keys(form)
        .map((key) => {
          return `${key}=${form[key]}`
        })
        .join('&')

      return `/download/consumption/request?${query}`
    },
    allowSubmit() {
      if (
        this.formLoading ||
        isBefore(this.endMonth, this.startMonth) ||
        differenceInMonths(this.endMonth, this.startMonth) > 11
      ) {
        return false
      }

      return true
    },
  },

  watch: {
    floor(newValue) {
      if (!newValue) {
        return
      }

      const floorItem = this.floorsMap[newValue]
      const { startMonth, endMonth, dataTypeList } = floorItem

      if (startMonth || endMonth) {
        this.$set(this.pickerOptions, 'disabledDate', (time) => {
          return (
            isBefore(time, new Date(startMonth)) ||
            isBefore(new Date(endMonth), time)
          )
        })
      } else {
        this.$set(this.pickerOptions, 'disabledDate', {})
      }

      this.startMonth = new Date(endMonth)
      this.endMonth = new Date(endMonth)

      if (dataTypeList?.length > 0) {
        this.dataTypeList = dataTypeList
        this.dataType = dataTypeList[0].id
      }
    },
  },

  mounted() {
    this.fetchOptions(false)
  },

  methods: {
    async fetchOptions() {
      this.formLoading = true

      const res = await this.$axios.$get('/download/consumption/options', {
        params: {
          id: this.floor,
        },
      })

      this.formLoading = false

      const { floors, show_floor_name: showFloorName, tips } = res.data

      this.floors = floors.map(({ id, name }) => ({ id, name }))
      this.floor = this.floors[0].id
      this.showFloorsSet = showFloorName
      this.tips = tips

      const floorsMap = {}

      floors.map((item) => {
        floorsMap[item.id] = item

        return item
      })

      this.floorsMap = floorsMap

      // init month
      this.startMonth = new Date(this.floorsMap[this.floor].endMonth)
      this.endMonth = new Date(this.floorsMap[this.floor].endMonth)
    },
    async save() {
      this.submitLoading = true

      await this.$download(this.downloadUrl)

      this.submitLoading = false
    },
  },
}
