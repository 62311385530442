//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Table, TableColumn } from 'element-ui'
import { formatDate } from '@/plugins/filters'

export default {
  components: {
    ElTable: Table,
    tableColumn: TableColumn,
  },

  model: {
    prop: 'data',
    event: 'input',
  },

  props: {
    data: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    startAtList: {
      type: Array,
      default: () => [],
    },
    endAtList: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      isSelectAll: false,
      selects: [],
      startOptions: {},
      endOptions: {},
    }
  },

  mounted() {
    this.$set(this.startOptions, 'disabledDate', (time) => {
      return !this.startAtList.includes(formatDate(time, 'yyyy-MM'))
    })

    this.$set(this.endOptions, 'disabledDate', (time) => {
      return !this.endAtList.includes(formatDate(time, 'yyyy-MM'))
    })
  },

  methods: {
    selectAllChange(value) {
      this.selects = value ? this.data.map((item) => item.id) : []

      this.emitSelect()
    },
    selectChange() {
      this.isSelectAll = this.selects.length === this.data.length

      this.emitSelect()
    },
    clearSelect() {
      this.selects = []
      this.isSelectAll = false

      this.emitSelect()
    },
    emitSelect() {
      this.$emit(
        'input',
        this.data.map((item) => {
          item.isSelect = this.selects.includes(item.id)

          return item
        })
      )
    },
  },
}
