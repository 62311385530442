//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    imgUrl: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    intro: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
    introStyle: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    style() {
      return {
        color: this.color,
      }
    },
  },
}
