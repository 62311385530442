//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { outputPdf } from '@/plugins/utils'
import { formatDate } from '@/plugins/filters'

const colors = {
  tags: {
    1: '#00aa64',
    2: '#0033ff',
    3: '#ff9900',
  },
}

export default {
  name: 'CalendarDeliveryConfirmPage',

  layout: 'page',

  async asyncData({ $axios, query }) {
    const res = await $axios.$get('/refrigerate/calendar/detail', {
      params: {
        date: query.date,
        cid: query.floor,
      },
    })

    return res.data
  },

  data() {
    const { query } = this.$route

    return {
      date: query.date,
      floor: query.floor,
      floorName: query.floorName,
      collectionAmount: null,
      photos: [],
      options: [],
      goodsCat: null,
      goodsList: [],
      pdfLoading: false,
    }
  },

  computed: {
    pageTitle() {
      return `${formatDate(this.date, 'M月d日(EEEEE)')}のお届け情報${
        this.floorName ? `(${this.floorName})` : ''
      }`
    },
    countLabel() {
      const active = this.options.find((item) => item.id === this.goodsCat)

      return `${active?.label}個数`
    },
  },

  mounted() {
    // set options first as default
    this.goodsCat = this.options[0]?.id

    this.fetchGoods()
  },

  methods: {
    async fetchDetail() {
      const res = await this.$axios.$get('/refrigerate/calendar/detail', {
        params: {
          date: this.date,
          cid: this.floor,
        },
      })

      const { collectionAmount, photos, options } = res.data

      this.collectionAmount = collectionAmount
      this.photos = photos
      this.options = options

      this.fetchGoods()
    },
    async fetchGoods() {
      const res = await this.$axios.$get('/refrigerate/calendar/goods', {
        params: {
          date: this.date,
          cid: this.floor,
          goodsCat: this.goodsCat,
        },
      })

      if (!res.data) {
        return
      }

      const { list } = res.data

      this.goodsList = list.map((item) => {
        if (item.tags) {
          item.tags = item.tags.map((tag) => {
            tag.color = colors.tags[tag.id] || ''

            return tag
          })
        }

        return item
      })
    },
    downloadPdf() {
      this.pdfLoading = true

      this.$nextTick(async () => {
        await outputPdf(
          document.getElementById('goods-list-pdf-wrap'),
          `${this.pageTitle}.pdf`
        )

        this.pdfLoading = false
      })
    },
  },
}
