//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { formatDate } from '@/plugins/filters'

export default {
  name: 'YasaipayHistoryPage',

  layout: 'page',

  data() {
    return {
      listLoading: false,
      floor: null,
      floors: null,
      date: new Date(),
      dateMax: new Date(),
      list: [],
      showUserName: null,
      showPayDevice: null,
      plans: [],
      plan: 'all',
      downloadCsvLoading: false,
    }
  },

  computed: {
    month() {
      return formatDate(this.date, 'yyyy-MM')
    },
    filterList() {
      if (this.plan === 'all') {
        return this.list
      }

      return this.list.filter((item) => item.plan === this.plan)
    },
  },

  mounted() {
    this.fetchList()
  },

  methods: {
    async fetchList(page) {
      this.listLoading = true

      const res = await this.$axios.$get('/yasaipay/history', {
        params: {
          month: this.month,
          id: this.floor,
        },
      })

      this.listLoading = false

      if (!res.data) {
        return
      }

      const { list, floors, displayUserName, displayPayDevice } = res.data

      if (floors && !this.floors) {
        this.floors = floors
        this.floor = floors[0].id
      }

      this.list = list

      const plansMap = {
        all: '全てのプラン',
      }

      list.forEach((item) => {
        if (!plansMap[item.plan]) {
          plansMap[item.plan] = item.plan
        }
      })

      this.plans = Object.keys(plansMap).map((key) => ({
        id: key,
        name: plansMap[key],
      }))

      this.showUserName = displayUserName
      this.showPayDevice = displayPayDevice
    },
    async downloadCsv() {
      this.downloadCsvLoading = true

      await this.$download(
        `/download/yasaipay/request?month=${this.month}&id=${this.floor}&plan=${this.plan}`
      )

      this.downloadCsvLoading = false
    },
  },
}
