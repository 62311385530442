import { render, staticRenderFns } from "./index.vue?vue&type=template&id=75699248&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Steps: require('/home/runner/work/myody_front/myody_front/components/steps/Steps.vue').default,Radio: require('/home/runner/work/myody_front/myody_front/components/radio/Radio.vue').default,FormItem: require('/home/runner/work/myody_front/myody_front/components/form/FormItem.vue').default,Button: require('/home/runner/work/myody_front/myody_front/components/button/Button.vue').default,Calendar: require('/home/runner/work/myody_front/myody_front/components/calendar/Calendar.vue').default,StepsEnd: require('/home/runner/work/myody_front/myody_front/components/steps/StepsEnd.vue').default,Form: require('/home/runner/work/myody_front/myody_front/components/form/Form.vue').default,Card: require('/home/runner/work/myody_front/myody_front/components/card/Card.vue').default})
