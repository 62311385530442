//
//
//
//

import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { BarChart, LineChart } from 'echarts/charts'
import {
  TooltipComponent,
  GridComponent,
  LegendComponent,
  DatasetComponent,
} from 'echarts/components'
import VChart from 'vue-echarts'

use([
  TooltipComponent,
  GridComponent,
  LegendComponent,
  DatasetComponent,
  BarChart,
  LineChart,
  CanvasRenderer,
])

export default {
  components: {
    VChart,
  },
  props: {
    dataset: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    const colors = ['#c5a166', '#dc5014']

    return {
      option: {
        color: colors,
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
          },
          backgroundColor: '#5e5f5e',
          borderColor: '#5e5f5e',
          padding: [6, 8],
          textStyle: {
            color: '#fff',
            fontSize: 12,
          },
          formatter(datas) {
            const text = [`${datas[0].value[0]}`]

            text.push(`合計金額: ${datas[datas.length - 1].value[2]}円`)

            for (let i = 0; i < datas.length; i++) {
              const data = datas[i]

              text.push(`${data.seriesName}: ${data.value[i + 1]}円`)
            }

            return text.join('<br />')
          },
        },
        grid: {
          left: '52px',
          right: '48px',
        },
        legend: {},
        xAxis: [
          {
            type: 'category',
            axisTick: {
              alignWithLabel: true,
            },
            axisLabel: {
              formatter(value) {
                return value.replace(/.+\//, '')
              },
            },
            splitLine: {
              show: true,
            },
          },
        ],
        yAxis: {
          type: 'value',
          name: '円',
          min: 0,
          interval: 2000,
          position: 'left',
          axisLine: {
            show: true,
          },
        },
        dataset: this.dataset,
        series: [
          {
            type: 'bar',
            stack: 'collection',
            yAxisIndex: 0,
            barWidth: 17,
            itemStyle: {
              opacity: 0.7,
            },
          },
          {
            type: 'bar',
            stack: 'collection',
            barWidth: 17,
            itemStyle: {
              opacity: 0.7,
            },
          },
        ],
      },
    }
  },
  watch: {
    dataset(newValue) {
      this.option.dataset = newValue
    },
  },
}
