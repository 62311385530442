import { render, staticRenderFns } from "./login.vue?vue&type=template&id=63cb0ef6&"
import script from "./login.vue?vue&type=script&lang=js&"
export * from "./login.vue?vue&type=script&lang=js&"
import style0 from "./login.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Input: require('/home/runner/work/myody_front/myody_front/components/input/Input.vue').default,FormItem: require('/home/runner/work/myody_front/myody_front/components/form/FormItem.vue').default,Button: require('/home/runner/work/myody_front/myody_front/components/button/Button.vue').default,Link: require('/home/runner/work/myody_front/myody_front/components/link/Link.vue').default,Form: require('/home/runner/work/myody_front/myody_front/components/form/Form.vue').default,PageLogin: require('/home/runner/work/myody_front/myody_front/components/page/PageLogin.vue').default})
