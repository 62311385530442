//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'PageLpPetitGohan',

  layout: 'page',

  data() {
    return {
      pageLoading: false,
      pptUrl: '',
      pptType: '',
      buttonUrl:
        '/contact?contactType=a&content=【オフィスでごはんプチプラン問い合わせ】',
    }
  },

  computed: {
    pptRenderUrl() {
      return this.pptUrl
        ? `https://view.officeapps.live.com/op/view.aspx?src=${this.pptUrl}`
        : ''
    },
  },

  mounted() {
    this.fetchOptions()
  },

  methods: {
    async fetchOptions() {
      this.pageLoading = true

      const res = await this.$axios.$get('/ppt')

      this.pageLoading = false

      const { url, type } = res.data

      this.pptUrl = url
      this.pptType = type
    },
    navContact() {
      this.$router.push({
        path: '/contact',
        query: {
          contactType: 'a',
          content: '【オフィスでごはんプチプラン問い合わせ】',
        },
      })
    },
  },
}
