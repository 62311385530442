//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Select } from 'element-ui'

export default {
  components: {
    'el-select': Select,
  },

  props: {
    searchable: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      keyword: '',
    }
  },

  computed: {
    curVal: {
      get() {
        return this.$attrs.value
      },
      set(newVal) {
        this.$emit('input', newVal)
      },
    },
  },

  created() {
    this.$nextTick(() => {
      this.debouncedQueryChange = this.$children[0].debouncedQueryChange
      this.handleQueryChange = this.$children[0].handleQueryChange
    })
  },

  methods: {
    doInput(value) {
      this.searchable &&
        this.debouncedQueryChange?.({
          target: {
            value,
          },
        })
    },
    doChange(visible) {
      if (this.searchable && visible === true) {
        this.keyword = ''
        this.handleQueryChange('')
      }
    },
  },
}
