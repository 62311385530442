//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'NewsListPage',

  layout: 'page',

  data() {
    const { query } = this.$route

    return {
      list: [],
      page: query.page || 1,
      total: 0,
      pageSize: 10,
      floor: null,
      floors: [],
    }
  },

  computed: {
    _list() {
      return this.list.map((item) => {
        item.link = `/news/detail?id=${item.id}`

        return item
      })
    },
  },

  mounted() {
    this.fetchList()
  },

  methods: {
    async fetchList(page = 1) {
      const res = await this.$axios.$get('/news/list', {
        params: {
          page,
          cid: this.floor,
        },
      })

      if (!res?.data) {
        return
      }

      const { list, total, pageSize, floors } = res.data

      this.list = list
      this.total = total
      this.pageSize = pageSize
      this.floors = floors
      this.page = page
    },
    toPage(page) {
      this.fetchList(page)
    },
  },
}
