//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { addMonths } from 'date-fns'
import { formatDate, currency } from '@/plugins/filters'

export default {
  name: 'OptionRequestHistoryPage',

  layout: 'page',

  data() {
    const { query } = this.$route

    return {
      listLoading: false,
      submitLoading: false,
      previewTab: 'count',
      floor: { id: query.floor },
      deliveryInfo: {
        totalCount: 0,
        tCount: 0,
        pCount: 0,
        oCount: 0,
      },
      tips: '',
      date: new Date(),
      dateMax: addMonths(new Date(), 1),
      startDate: null,
      endDate: null,
      options: [],
      goodsCat: null,
      catList: [],
      catListMap: [],
      perDeliveryCount: 0,
      deliveryTotal: 0,
      priceTotal: 0,
      employeesPriceTotal: 0,
      billingAmount: 0,
      priceList: [],
      employeesPriceList: [],
      isAdmin: null,
      canRequest: null,
      warnTips: '',
      sorts: [
        {
          name: 'price',
          label: '販売価格',
          type: 'default',
        },
        {
          name: 'employeesPrice',
          label: '従業員販売価格',
          type: 'default',
        },
        {
          name: 'lastMonthDeliveryCount',
          label: '前月のリクエスト数',
          type: 'default',
        },
        {
          name: 'count',
          label: 'ご希望お届け数',
          type: 'default',
        },
      ],
      sortActive: '',
      sortType: 'default',
    }
  },

  head() {
    return {
      bodyAttrs: {
        class: this.$options.name,
      },
    }
  },
  computed: {
    month() {
      return formatDate(this.date, 'yyyy-MM')
    },
    allGoodsList() {
      let list = []

      Object.values(this.catListMap).forEach((item) => {
        list = list.concat(item)
      })

      return list
    },
    goodsList() {
      if (this.goodsCat === 'all') {
        return this.allGoodsList
      }

      return this.catListMap[this.goodsCat]
    },
    goodsSortList() {
      if (!this.goodsList) {
        return []
      }

      const newList = this.goodsList.slice()

      if (!this.sortActive) {
        return newList
      }

      return this.handleSort(newList, this.sortActive, this.sortType)
    },
    countList() {
      return this.catList.map((item) => {
        let count = 0
        let price = 0
        let employeesPrice = 0

        for (let i = 0; i < item.goodsList.length; i++) {
          const goods = item.goodsList[i]

          count += goods.count
          price += goods.price * goods.count
          employeesPrice += goods.employeesPrice * goods.count
        }

        return {
          name: item.catName,
          text: `${count} 個`,
          count,
          price,
          employeesPrice,
        }
      })
    },
    allowSubmit() {
      if (!this.isAdmin && !this.canRequest) {
        return false
      }

      return true
    },
  },

  watch: {
    countList(newValue) {
      let perDeliveryCount = 0
      let priceTotal = 0
      let employeesPriceTotal = 0

      for (let i = 0; i < newValue.length; i++) {
        const item = newValue[i]

        perDeliveryCount += item.count
        priceTotal += item.price
        employeesPriceTotal += item.employeesPrice
      }

      this.perDeliveryCount = perDeliveryCount
      this.deliveryTotal = this.perDeliveryCount
      this.priceTotal = priceTotal
      this.employeesPriceTotal = employeesPriceTotal
      this.billingAmount = this.priceTotal - this.employeesPriceTotal

      this.priceList = newValue.map((item) => {
        return {
          name: item.name,
          text: currency(item.price),
        }
      })

      this.employeesPriceList = newValue.map((item) => {
        return {
          name: item.name,
          text: currency(item.employeesPrice),
        }
      })
    },
  },

  mounted() {
    this.fetchOptions()
  },

  methods: {
    async fetchOptions() {
      this.listLoading = true
      this.options = []

      const res = await this.$axios.$get('/option/options', {
        params: {
          month: this.month,
          cid: this.floor.id,
          isHistory: true,
        },
      })

      this.listLoading = false

      if (!res.data) {
        return
      }

      const {
        startDate,
        endDate,
        catList,
        tips,
        floor,
        deliveryInfo,
        isAdmin,
        canRequest,
        warnTips,
      } = res.data

      this.startDate = startDate
      this.endDate = endDate
      this.deliveryInfo = deliveryInfo
      this.isAdmin = isAdmin
      this.canRequest = canRequest
      this.warnTips = warnTips
      this.floor = floor || {}

      const options = []
      const catListMap = {}

      if (catList.length > 0) {
        options.push({
          id: 'all',
          label: '全ての商品',
        })
      }

      catList.map((item) => {
        options.push({
          id: item.catID,
          label: item.catName,
        })

        catListMap[item.catID] = item.goodsList.map((goods) => {
          goods.catID = item.catID

          if (!goods.count) {
            goods.count = 0
          }

          return goods
        })

        return item
      })

      this.catList = catList.slice()
      this.options = options
      this.catListMap = catListMap
      this.tips = tips

      // set options first as default
      this.goodsCat = this.options[0]?.id
    },
    goodsCountChange(item) {
      const index = this.catList.findIndex((cat) => cat.catID === item.catID)
      const catItem = this.catList[index]
      const goodsIndex = catItem.goodsList.findIndex(
        (goods) => goods.id === item.id
      )

      catItem.goodsList[goodsIndex].count = item.count

      this.$set(this.catList, index, catItem)
    },
    doSort(item) {
      if (this.sortActive !== item.name) {
        if (this.sortActive) {
          const lastSort = this.sorts.find(
            (sort) => sort.name === this.sortActive
          )

          if (lastSort) {
            lastSort.type = 'default'
          }
        }

        this.sortActive = item.name

        item.type = 'desc'
      } else {
        switch (item.type) {
          case 'default':
            item.type = 'desc'
            break
          case 'desc':
            item.type = 'asc'
            break
          case 'asc':
            item.type = 'default'
            this.sortActive = ''
            break
          default:
            break
        }
      }

      this.sortType = item.type
    },
    handleSort(data, key, type) {
      data.sort((a, b) => a[key] - b[key])

      if (type === 'desc') {
        data.reverse()
      }

      return data
    },
    resetAll() {
      this.catList = this.catList.map((item) => {
        item.goodsList = item.goodsList.map((goods) => {
          goods.count = 0

          return goods
        })

        return item
      })
    },
    async submit() {
      this.listLoading = true
      this.submitLoading = true

      const form = {
        month: this.month,
        cid: this.floor.id,
        catList: this.catList.map((cat) => {
          return {
            catID: cat.catID,
            goodsList: cat.goodsList.map((goods) => {
              return {
                id: goods.id,
                price: goods.price,
                count: goods.count,
              }
            }),
          }
        }),
        priceTotal: this.priceTotal,
        employeesPriceTotal: this.employeesPriceTotal,
        billingAmount: this.billingAmount,
      }

      const res = await this.$axios.post('/option/requests', form)

      this.listLoading = false
      this.submitLoading = false

      if (res.status !== 200) {
        return
      }

      this.$toast({
        message: '保存しました',
      })

      this.$router.push('/option/request-complete')
    },
  },
}
