import { render, staticRenderFns } from "./GoodsHistoryItem.vue?vue&type=template&id=614482f4&"
import script from "./GoodsHistoryItem.vue?vue&type=script&lang=js&"
export * from "./GoodsHistoryItem.vue?vue&type=script&lang=js&"
import style0 from "./GoodsHistoryItem.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Link: require('/home/runner/work/myody_front/myody_front/components/link/Link.vue').default})
