//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'MobileResult',

  layout: 'mobile',
}
