//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    list: {
      type: Array,
      default: () => {},
    },
    color: {
      type: String,
      default: '',
    },
    introStyle: {
      type: Object,
      default: () => {},
    },
  },
}
