//
//
//
//

import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { BarChart, LineChart } from 'echarts/charts'
import {
  TooltipComponent,
  GridComponent,
  LegendComponent,
  DatasetComponent,
} from 'echarts/components'
import VChart from 'vue-echarts'

use([
  TooltipComponent,
  GridComponent,
  LegendComponent,
  DatasetComponent,
  BarChart,
  LineChart,
  CanvasRenderer,
])

export default {
  components: {
    VChart,
  },
  props: {
    dataset: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    const colors = ['#00aa64', '#c5a166', '#03b8df']

    return {
      option: {
        color: colors,
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
          },
          backgroundColor: '#5e5f5e',
          borderColor: '#5e5f5e',
          padding: [6, 8],
          textStyle: {
            color: '#fff',
            fontSize: 12,
          },
          formatter(datas) {
            let text = `${datas[0].value[0]}<br />`

            datas.map((data, index) => {
              let value = data.value[index + 1]

              switch (data.seriesIndex) {
                case 0:
                case 1:
                  value += '個'
                  break
                case 2:
                  value += '%'
                  break
                default:
                  break
              }

              text += `${data.seriesName}: ${value}<br />`

              return data
            })

            return text
          },
        },
        grid: {
          left: '48px',
          right: '48px',
        },
        legend: {
          data: [
            {
              name: '配達個数',
              icon: 'roundRect',
            },
            {
              name: '回収個数',
              icon: 'roundRect',
            },
            {
              name: '利用率',
              icon: 'roundRect',
            },
          ],
        },
        xAxis: [
          {
            type: 'category',
            axisTick: {
              alignWithLabel: true,
            },
            axisLabel: {
              formatter(value) {
                return value.replace(/.+\//, '')
              },
            },
            splitLine: {
              show: true,
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
            name: '個数',
            min: 0,
            max(value) {
              return Math.ceil(value.max / 100) * 100
            },
            interval: 100,
            position: 'left',
            axisLine: {
              show: true,
            },
          },
          {
            name: '回収個数',
            min: 0,
            max(value) {
              return Math.ceil(value.max / 100) * 100
            },
            minInterval: 100,
            show: false,
          },
          {
            type: 'value',
            name: '利用率',
            min: 0,
            max: 100,
            position: 'right',
            axisLine: {
              show: true,
            },
            splitLine: {
              show: false,
            },
          },
        ],
        dataset: this.dataset,
        series: [
          {
            type: 'bar',
            barWidth: 17,
            datasetIndex: 0,
            yAxisIndex: 0,
            itemStyle: {
              opacity: 0.7,
            },
          },
          {
            type: 'bar',
            barWidth: 17,
            datasetIndex: 0,
            yAxisIndex: 1,
            itemStyle: {
              opacity: 0.7,
            },
          },
          {
            type: 'line',
            datasetIndex: 0,
            yAxisIndex: 2,
            symbol: 'circle',
            symbolSize: 16,
            itemStyle: {
              opacity: 0.9,
            },
          },
        ],
      },
    }
  },
  watch: {
    dataset(newValue) {
      this.option.dataset = newValue
    },
  },
}
