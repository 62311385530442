//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    iconUrl: {
      type: String,
      default: '',
    },
    desc: {
      type: String,
      default: '',
    },
    btnBackText: {
      type: String,
      default: 'トップに戻る',
    },
  },
}
