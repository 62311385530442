//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    list: {
      type: Array,
      default: () => {},
    },
    groupSize: {
      type: Number,
      default: 6,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    showCat: {
      type: Boolean,
      default: false,
    },
    showEnergy: {
      type: Boolean,
      default: false,
    },
    showUnit: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    listGroup() {
      const groups = []

      for (let i = 0; i < this.list.length; i += this.groupSize) {
        groups.push(this.list.slice(i, i + this.groupSize))
      }

      return groups
    },
  },
}
