import { render, staticRenderFns } from "./spot.vue?vue&type=template&id=70f26a8a&"
import script from "./spot.vue?vue&type=script&lang=js&"
export * from "./spot.vue?vue&type=script&lang=js&"
import style0 from "./spot.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/home/runner/work/myody_front/myody_front/components/button/Button.vue').default,Link: require('/home/runner/work/myody_front/myody_front/components/link/Link.vue').default,ListCase: require('/home/runner/work/myody_front/myody_front/components/list/ListCase.vue').default,CardReserve: require('/home/runner/work/myody_front/myody_front/components/card/CardReserve.vue').default,CardContact: require('/home/runner/work/myody_front/myody_front/components/card/CardContact.vue').default,Card: require('/home/runner/work/myody_front/myody_front/components/card/Card.vue').default})
