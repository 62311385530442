//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { addMonths } from 'date-fns'
import { outputPdf } from '@/plugins/utils'
import { formatDate } from '@/plugins/filters'

export default {
  name: 'FormMenuDownload',

  layout: 'page',

  data() {
    return {
      formLoading: false,
      submitLoading: false,
      pdfLoading: false,
      fetchLoading: false,
      floorId: null,
      floors: null,
      showFloorsSet: null,
      planId: null,
      monthStart: addMonths(new Date(), -1),
      monthOptions: {},
      courseId: null,
      purposeId: null,
      contentId: null,
      tableGroupSizes: [],
      downloadFilter: [],
      displayType: '',
      listMenu: {},
    }
  },

  computed: {
    formDisabled() {
      return this.formLoading || this.submitLoading || this.pdfLoading
    },
    showFloors() {
      return this.showFloorsSet ?? this.floors?.length > 1
    },
    floor() {
      return this.floors?.find((item) => item.id === this.floorId)
    },
    plansMap() {
      return this.floor?.reduce((acc, item) => {
        acc[item.id] = item.plans

        return acc
      }, {})
    },
    plans() {
      return this.floor?.plans ?? []
    },
    plan() {
      return this.plans.find((item) => item.id === this.planId)
    },
    groupId() {
      return this.plan?.group_id
    },
    courseList() {
      return this.plan?.courseList ?? []
    },
    course() {
      return this.courseList?.find((item) => item.id === this.courseId)
    },
    month() {
      return formatDate(this.monthStart, 'yyyy-MM')
    },
    monthList() {
      return this.course?.monthList
    },
    monthValids() {
      return this.monthList?.map((item) => item.month) ?? []
    },
    purposeList() {
      return (
        this.monthList?.find((item) => item.month === this.month)
          ?.purposeList ?? []
      )
    },
    contentList() {
      return (
        this.purposeList.find((item) => item.id === this.purposeId)
          ?.contentList ?? []
      )
    },
    content() {
      return this.contentList?.find((item) => item.id === this.contentId)
    },
    downloadFilterOptions() {
      return this.content?.options ?? []
    },
    listTableName() {
      const map = {
        yasai_table: 'ListProductTable',
        gohan_table: 'ListProductTable',
        yasai_pic_nutrients_table: 'ListProductRichTable',
        gohan_pic_nutrients_table: 'ListProductRichTable',
        yasai_employee_nutrients_table: 'ListProductRichTable',
        gohan_employee_nutrients_table: 'ListProductRichTable',
      }

      return map[this.displayType]
    },
    isEmployeeNutrientsTable() {
      return (
        this.purposeId === 1 &&
        [
          'all_products',
          'requested_products',
          'monthly_requested_products',
          'addon_requested_products',
        ].includes(this.contentId) &&
        this.downloadFilter.includes('nutrients')
      )
    },
    pdfListGroup() {
      let groups = []

      if (!this.listMenu?.list) {
        return groups
      }

      switch (this.purposeId) {
        case 1:
          {
            if (this.isEmployeeNutrientsTable) {
              groups = this.getTableGroups()

              break
            }

            const displayTypeMap = {
              yasai_category_grid: 30,
              gohan_category_grid: 30,
              yasai_product_grid: 24,
              gohan_product_grid: 24,
              gohan_petit_table: 6,
              1: 30,
              2: 24,
              3: 6,
            }
            const groupCount = displayTypeMap[this.displayType]

            for (let i = 0; i < this.listMenu?.list?.length; i += groupCount) {
              groups.push(this.listMenu.list.slice(i, i + groupCount))
            }
          }
          break
        case 2:
          groups = this.getTableGroups()

          break

        default:
          break
      }

      return groups
    },
    pdfFooterTips() {
      return `※${formatDate(
        new Date(),
        'yyyy/MM/dd'
      )} 時点の情報です。商品の規格が変更となる場合がございます。`
    },
  },

  watch: {
    floorId() {
      this.planId = this.plans?.find((item) => item.id === this.planId)
        ? this.planId
        : this.plans?.[0]?.id
    },
    planId(newValue) {
      this.courseId = this.courseList?.find((item) => item.id === this.courseId)
        ? this.courseId
        : this.courseList?.[0]?.id
    },
    courseId(newValue) {
      if (this.monthValids?.length) {
        this.$set(this.monthOptions, 'disabledDate', (time) => {
          return !this.monthValids.includes(formatDate(time, 'yyyy-MM'))
        })

        this.monthStart = new Date(this.monthValids[0])
      } else {
        this.$set(this.monthOptions, 'disabledDate', {})
      }
    },
    monthStart() {
      this.purposeId = this.purposeList.find(
        (item) => item.id === this.purposeId
      )
        ? this.purposeId
        : this.purposeList[0]?.id
    },
    purposeId() {
      this.contentId = this.content ? this.contentId : this.contentList?.[0]?.id
    },
    content(newValue) {
      if (newValue === undefined) {
        this.contentId = this.contentList?.[0]?.id
      }
    },
    downloadFilterOptions() {
      this.downloadFilter = this.downloadFilterOptions
        .filter((item) => item.checked)
        .map((item) => item.column)
    },
  },

  mounted() {
    this.fetchOptions()
  },

  methods: {
    async fetchOptions() {
      this.formLoading = true

      const res = await this.$axios.$get('/download/menu/options')

      this.formLoading = false

      const { floors, show_floor_name: showFloorName } = res.data

      this.floors = floors
      this.showFloorsSet = showFloorName

      // Set floorId default value
      if (!this.floorId) {
        this.floorId = this.floors[0]?.id
      }

      this.updatePreview()
    },
    async fetchList() {
      this.fetchLoading = true

      const productsApi =
        this.groupId === 'yasai'
          ? '/download/menu/products/yasai'
          : '/download/menu/products/gohan'

      const res = await this.$axios.$get(productsApi, {
        params: {
          floor: this.floorId,
          month: this.month,
          purposeId: this.purposeId,
          contentId: this.contentId,
          plan: this.planId,
          displayType: this.displayType,
        },
      })

      this.fetchLoading = false
      this.listMenu = res.data

      if (this.purposeId === 2 || this.isEmployeeNutrientsTable) {
        this.tableGroupSizes = []

        this.$nextTick(() => {
          this.rejustTableGroupSize()
        })
      }
    },
    getTableGroups() {
      const groups = []

      if (this.tableGroupSizes.length < 1) {
        return this.listMenu.list?.length > 0 ? [this.listMenu.list] : []
      }

      for (let i = 0, j = 0; i < this.tableGroupSizes.length; i++) {
        const curSize = this.tableGroupSizes[i]

        groups.push(this.listMenu.list.slice(j, j + curSize))
        j += curSize
      }

      return groups
    },
    updateDisplayType() {
      if (this.isEmployeeNutrientsTable) {
        this.displayType =
          this.groupId === 'yasai'
            ? 'yasai_employee_nutrients_table'
            : 'gohan_employee_nutrients_table'
      } else {
        this.displayType = this.content?.displayType
      }
    },
    updatePreview() {
      this.$nextTick(() => {
        this.updateDisplayType()
        this.fetchList()
      })
    },
    rejustTableGroupSize() {
      const trs = document.querySelectorAll(
        '.menu-list-group > .el-table > .el-table__body-wrapper tr'
      )
      const marginTop = 111
      const marginBottom = 70
      const perPageHeight = 1633 - marginTop - marginBottom
      const pageSizes = []

      let pageHeight = 0
      let curPageSize = 0

      const isTableSpanMode =
        ['yasai_pic_nutrients_table', 'gohan_pic_nutrients_table'].includes(
          this.displayType
        ) &&
        ['nutrients', 'goodsIntro'].every((item) =>
          this.downloadFilter.includes(item)
        )

      if (isTableSpanMode) {
        for (let i = 1; i < trs.length; i = i + 2) {
          const offsetHeight = trs[i].offsetHeight + trs[i - 1].offsetHeight

          pageHeight += offsetHeight
          curPageSize += 1

          if (i === trs.length - 1) {
            pageSizes.push(curPageSize)

            break
          }

          const trNext = trs[i + 2]
          const nextOffsetHeight =
            trs[i + 2].offsetHeight + trs[i + 1].offsetHeight

          if (trNext && pageHeight + nextOffsetHeight > perPageHeight) {
            pageSizes.push(curPageSize)

            pageHeight = 0
            curPageSize = 0
          }
        }
      } else {
        for (let i = 0; i < trs.length; i++) {
          const offsetHeight = trs[i].offsetHeight

          pageHeight += offsetHeight
          curPageSize += 1

          if (i === trs.length - 1) {
            pageSizes.push(curPageSize)

            break
          }

          const trNext = trs[i + 1]

          if (trNext && pageHeight + trNext.offsetHeight > perPageHeight) {
            pageSizes.push(curPageSize)

            pageHeight = 0
            curPageSize = 0
          }
        }
      }

      this.tableGroupSizes = pageSizes
    },
    async pdfDownload() {
      const $dom = document.getElementById('menu-list')
      const dom = $dom.cloneNode(true)
      const $pdfWrap = document.getElementById('menu-list-pdf-wrap')

      dom.id = 'menu-list-pdf'

      $pdfWrap.append(dom)

      const pdfOpts = {}

      if (this.purposeId === 1 && !this.isEmployeeNutrientsTable) {
        pdfOpts.a4Width = 595
        // pdfOpts.mode = 'preview'
      }

      this.pdfLoading = true

      await outputPdf($pdfWrap, `${this.listMenu.title}.pdf`, pdfOpts)

      this.pdfLoading = false

      $pdfWrap?.replaceChildren()
    },
  },
}
