//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'LoginPage',

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from
    })
  },

  data() {
    return {
      username: '',
      password: '',
      isSave: false,
      formLoading: false,
      redirectRoutes: [
        'login',
        'passwordreset',
        'passwordreset-complete',
        'passwordreset-success',
      ],
    }
  },

  created() {
    const { code } = this.$route.query

    if (!code && this.$auth.loggedIn) {
      this.$router.push('/')
    }
  },

  mounted() {
    const { code } = this.$route.query

    if (code) {
      this.checkCodeLogin(code)
    }
  },

  methods: {
    async userLogin() {
      this.formLoading = true

      const res = await this.$auth.loginWith('local', {
        data: {
          username: this.username,
          password: this.password,
        },
      })

      this.formLoading = false

      if (res.status !== 200) {
        return
      }

      // TODO: need to set token expiresAt

      // default navigation to page that before login
      // TODO: maybe change to redirect to home page only
      if (
        this.prevRoute?.name &&
        !this.redirectRoutes.includes(this.prevRoute.name) &&
        this.$route.query.redirect !== 'login'
      ) {
        this.$router.back()
      } else {
        this.$router.replace('/')
      }

      this.$toast({
        message: 'ログイン成功',
      })
    },
    async checkCodeLogin(code) {
      this.formLoading = true

      this.$toast({
        message: '自動ログイン...',
      })

      const res = await this.$axios.$get('/auth/login/admin', {
        params: {
          code,
        },
      })

      const { token } = res.data

      if (!token) {
        this.formLoading = false

        this.$toast({
          message: 'get token error',
        })

        return
      }

      // TODO: need to set token expiresAt

      await this.$auth.setUserToken(token)

      this.formLoading = false

      // this.$router.replace('/')
      window.location = '/'
    },
  },
}
