//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'PasswordresetCompletePage',

  auth: false,
}
