//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    color: {
      type: String,
      default: '#68b7dd',
    },
  },
}
