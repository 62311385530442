//
//
//
//
//
//
//
//
//
//

import { addMonths, subMonths, isAfter, isSameMonth } from 'date-fns'

export default {
  props: {
    value: {
      type: Date,
      default: new Date(),
    },
    max: {
      type: Date,
      default: null,
    },
  },

  computed: {
    isMax() {
      if (!this.max) {
        return false
      }

      return isSameMonth(this.value, this.max) || isAfter(this.value, this.max)
    },
  },

  methods: {
    last() {
      const newValue = subMonths(this.value, 1)

      this.$emit('input', newValue)
      this.$emit('change', newValue)
    },
    next() {
      const newValue = addMonths(this.value, 1)

      this.$emit('input', newValue)
      this.$emit('change', newValue)
    },
  },
}
