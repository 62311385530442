//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'PageLpOyatsu',

  layout: 'page',

  data() {
    return {
      caseList: [
        {
          id: 1,
          title: 'Point 1',
          intro:
            '3〜4 時間おきに食べ物を摂取することで、血糖値が安定して脳のエネルギーが常に供給される状態となり、業務の生産性が維持できます。',
          imgUrl: 'img/tmp/lp-oyatsu-p1.jpg',
        },
        {
          id: 2,
          title: 'Point 2',
          intro:
            'ビタミン・ミネラル・食物繊維など不足しがちな栄養素を健康的な間食を摂ることで栄養補助が期待できます。',
          imgUrl: 'img/tmp/lp-oyatsu-p2.jpg',
        },
        {
          id: 3,
          title: 'Point 3',
          intro:
            'ビタミン・ミネラル・食物繊維など不足しがちな栄養素を健康的な間食を摂ることで栄養補助が期待できます。',
          imgUrl: 'img/tmp/lp-oyatsu-p3.jpg',
        },
      ],
      exampleList: [
        'ドライフルーツ',
        'ナッツ',
        'やさいチップス',
        'おしゃぶり昆布',
        '茎わかめ',
        'プルーン',
        'おからクッキー',
        '⾖菓⼦',
        '⽶菓',
        '⽢栗',
        '⼩⿂',
        'こんにゃくチップス',
        '植物性プロテインバー',
        '⽞⽶ブラン',
        'ロカボケーキ',
        '果実ゼリー',
        '芋けんぴ',
        'ラムネ',
        '有平糖',
        '⾦平糖',
        'など、全40-50種類',
      ],
      tablePriceData: [
        {
          planName: '【月50個お届け】',
          monthFee: 6600,
          price: 5000,
        },
        {
          planName: '【月100個お届け】',
          monthFee: 12100,
          price: 1000,
        },
        {
          planName: '【月150個お届け】',
          monthFee: 16500,
          price: 15000,
        },
      ],
    }
  },
}
