//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'OdgCalendarDetailPage',

  layout: 'page',

  data() {
    const { query } = this.$route

    return {
      date: new Date(query.date),
      floor: query.floor,
      goods: {},
    }
  },

  mounted() {
    this.fetchDetail()
  },

  methods: {
    fetchDetail() {
      // const res = await this.$axios.$get('/refrigerate/calendar/detail', {
      //   params: {
      //     date: this.date,
      //   },
      // })
      // const { goods, list } = res.data

      this.goods = {
        id: '23',
        goodsTitle: 'ミニサラダ',
        cover: {
          url: 'https://tmp-cms.officedeyasai.jp//img/req/202207/pmm_4_23.jpg?v=20220601130508',
          url2: 'https://tmp-cms.officedeyasai.jp//img/req/202207/pmm_4_23_2.jpg?v=20220601130508',
        },
        goodsIntro:
          '手軽に食べられるドレッシング付きのミニサラダ。<br />\r\n【お届け予定商品】<br />\r\n蒸し鶏根菜サラダ<br />\r\n焼コーンのサラダ<br />\r\n蒸し鶏とひじきサラダ<br />\r\nオクラと山芋のネバネバサラダ<br />\r\n<br />\r\n<br />\r\n消費期限：2日間（お届け日含む）<br />\r\n※お届け商品はお届けカレンダーでご確認ください。<br />\r\n※お届け商品は変更となる場合があります。',
        tags: [
          {
            id: 1,
            label: 'New',
          },
          {
            id: 2,
            label: 'おすすめ',
          },
        ],
        typeTag: {
          id: '1',
          label: '生',
        },
        price: 100,
        employeesPrice: 100,
        lastMonthDeliveryCount: 0,
        count: 5,
        deliveryTypes: ['t', 'p'],
        limit: 5,
        optionButton: {
          display: true,
          name: 'ごはんの注文はこちら',
        },
      }
    },
  },
}
