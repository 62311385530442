//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    id: {
      type: String,
      default: '',
    },
    cover: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    tag: {
      type: Object,
      default: () => {},
    },
    intro: {
      type: String,
      default: '',
    },
    price: {
      type: Number,
      default: 0,
    },
    sellCount: {
      type: Number,
      default: 0,
    },
  },

  methods: {
    showDetail() {
      this.$emit('onDetail', {
        id: this.id,
      })
    },
    showCart() {
      this.$emit('onCart', {
        id: this.id,
      })
    },
  },
}
