//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    id: {
      type: String,
      default: '',
    },
    cover: {
      type: String,
      default: '',
    },
    goodsTitle: {
      type: String,
      default: '',
    },
    goodsIntro: {
      type: String,
      default: '',
    },
    price: {
      type: Number,
      default: null,
    },
    employeesPrice: {
      type: Number,
      default: null,
    },
    count: {
      type: Number,
      default: 0,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    listLabel() {
      const list = []

      if (this.price) {
        list.push({
          name: '販売価格',
          text: `${this.price}円`,
        })
      }

      if (this.employeesPrice) {
        list.push({
          name: '従業員販売価格',
          text: `${this.employeesPrice}円`,
        })
      }

      if (this.count) {
        list.push({
          name: ' ',
          text: `${this.count}こ`,
        })
      }

      return list
    },
  },

  methods: {
    select(value) {
      this.$emit('select', value)
    },
  },
}
