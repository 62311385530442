//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { addMonths } from 'date-fns'
import { formatDate, currency } from '@/plugins/filters'
import Report from '@/report/sentryOdyList'

const colors = {
  tags: '#c6291f',
  typeTags: {
    1: '#00aa64',
    2: '#0033ff',
    3: '#ff9900',
  },
}

export default {
  name: 'OdyRequestPage',

  mixins: [Report],

  layout: 'page',

  data() {
    const { query } = this.$route

    return {
      listLoading: false,
      submitLoading: false,
      previewTab: 'count',
      floor: { id: query.floor },
      deliveryInfo: {
        totalCount: 0,
        tCount: 0,
        pCount: 0,
        oCount: 0,
      },
      date: addMonths(new Date(), 1),
      startDate: null,
      endDate: null,
      options: [],
      goodsCat: null,
      catList: [],
      catListMap: [],
      monthUsageFee: null,
      sellCountBudget: 0,
      deliveryTotal: 0,
      priceTotal: 0,
      employeesPriceTotal: 0,
      billingAmount: 0,
      priceList: [],
      employeesPriceList: [],
      banners: [],
      isAdmin: null,
      canRequest: null,
      warnTips: '',
      yasaiVer: null,
      pageRightWrapStyle: {
        top: 0,
      },
      feedbackNeeds: '',
      sortActive: '',
      sortType: 'default',
      priceFilter: [],
    }
  },

  head() {
    return {
      bodyAttrs: {
        class: this.$options.name,
      },
    }
  },

  computed: {
    month() {
      return formatDate(this.date, 'yyyy-MM')
    },
    allGoodsList() {
      let list = []

      Object.values(this.catListMap).forEach((item) => {
        list = list.concat(item)
      })

      return list
    },
    goodsList() {
      return this.goodsCat === 'all'
        ? this.allGoodsList
        : this.catListMap[this.goodsCat]
    },
    goodsFilterList() {
      if (!this.goodsList) {
        return []
      }

      if (this.priceFilter.length < 1) {
        return this.goodsList
      }

      return this.goodsList.filter((item) =>
        this.priceFilter.includes(item.price)
      )
    },
    goodsSortList() {
      if (!this.goodsFilterList) {
        return []
      }

      const newList = this.goodsFilterList.slice()

      if (!this.sortActive) {
        return newList
      }

      return this.handleSort(newList, this.sortActive, this.sortType)
    },
    goodsPriceList() {
      if (!this.allGoodsList) {
        return []
      }
      return this.allGoodsList
        .reduce((acc, cur) => {
          if (!acc.includes(cur.price)) {
            acc.push(cur.price)
          }

          return acc
        }, [])
        .sort((a, b) => a - b)
    },
    countList() {
      return this.catList.map((item) => {
        let count = 0
        let price = 0
        let employeesPrice = 0

        for (let i = 0; i < item.goodsList.length; i++) {
          const goods = item.goodsList[i]

          count += goods.count
          price += goods.price * goods.count
          employeesPrice += goods.employeesPrice * goods.count
        }

        return {
          name: item.catName,
          text: `${count} 個`,
          count,
          price,
          employeesPrice,
        }
      })
    },
    deliveryTotalText() {
      return `${this.deliveryTotal}/${this.sellCountBudget} 個`
    },
    allowSubmit() {
      if (!this.isAdmin && !this.canRequest) {
        return false
      }

      return (
        !this.listLoading &&
        (this.deliveryTotal === this.sellCountBudget || this.isAdmin)
      )
    },
    version() {
      return this.yasaiVer
    },
    sorts() {
      const sortsOpts = [
        {
          name: 'price',
          label: '販売価格',
          type: 'default',
        },
        {
          name: 'employeesPrice',
          label: '従業員販売価格',
          type: 'default',
        },
        {
          name: 'lastMonthDeliveryCount',
          label: '前月のリクエスト数',
          type: 'default',
        },
        {
          name: 'count',
          label: 'ご希望お届け数',
          type: 'default',
        },
      ]

      if (this.version === 2) {
        return sortsOpts.filter((item) => item.name !== 'price')
      }

      return sortsOpts
    },
  },

  watch: {
    countList(newValue) {
      let deliveryTotal = 0
      let priceTotal = 0
      let employeesPriceTotal = 0

      newValue.map((item) => {
        deliveryTotal += item.count
        priceTotal += item.price
        employeesPriceTotal += item.employeesPrice

        return item
      })

      this.deliveryTotal = deliveryTotal
      this.priceTotal = priceTotal
      this.employeesPriceTotal = employeesPriceTotal
      this.billingAmount = this.monthUsageFee + priceTotal - employeesPriceTotal

      this.priceList = newValue.map((item) => {
        return {
          name: item.name,
          text: currency(item.price),
        }
      })

      this.employeesPriceList = newValue.map((item) => {
        return {
          name: item.name,
          text: currency(item.employeesPrice),
        }
      })
    },
  },

  mounted() {
    this.fetchOptions()
  },

  methods: {
    async fetchOptions() {
      this.listLoading = true
      this.options = []

      const res = await this.$axios.$get('/refrigerate/options', {
        params: {
          month: this.month,
          cid: this.floor.id,
          isHistory: 0,
        },
      })

      this.listLoading = false

      const {
        startDate,
        endDate,
        catList,
        monthUsageFee,
        floor,
        deliveryInfo,
        sellCountBudget,
        banners,
        isAdmin,
        canRequest,
        warnTips,
        yasaiVer,
        needs,
      } = res.data

      this.startDate = startDate
      this.endDate = endDate
      this.deliveryInfo = deliveryInfo
      this.sellCountBudget = sellCountBudget
      this.banners = banners?.map((item) => {
        item.link = item.link.includes('http')
          ? item.link
          : `${window.location.search}${item.link}`

        return item
      })
      this.isAdmin = isAdmin
      this.canRequest = canRequest
      this.warnTips = warnTips
      this.yasaiVer = yasaiVer
      this.feedbackNeeds = needs
      this.floor = floor

      const options = []
      const catListMap = {}

      if (catList.length > 0) {
        options.push({
          id: 'all',
          label: '全ての商品',
        })
      }

      catList.map((item) => {
        options.push({
          id: item.catID,
          label: item.catName,
        })

        catListMap[item.catID] = item.goodsList.map((goods) => {
          goods.catID = item.catID

          if (!goods.count) {
            goods.count = 0
          }

          if (this.version === 2) {
            delete goods.price
          }

          if (goods.tags) {
            goods.tags = goods.tags.map((tag) => {
              tag.color = colors.tags

              return tag
            })
          }

          if (goods.typeTag) {
            goods.typeTags = [goods.typeTag]
          }

          if (goods.typeTags) {
            goods.typeTags = goods.typeTags.map((tag) => {
              tag.color = colors.typeTags[tag.id] || ''

              return tag
            })
          }

          if (goods.optionButton && !goods.optionButton.link) {
            goods.optionButton.link = encodeURI(
              `/option/request?floor=${this.floor.id}&gcn=ごはん`
            )
          }

          return goods
        })

        return item
      })

      this.catList = catList.slice()
      this.options = options
      this.catListMap = catListMap
      this.monthUsageFee = monthUsageFee

      // set options first as default
      this.goodsCat = this.options[0]?.id

      this.$nextTick(() => {
        if (this.$refs.pageRight) {
          this.pageRightWrapStyle.top = `${
            -this.$refs.pageRight.$el.offsetTop + 72
          }px`
        }
      })

      this.$nextTick(() => {
        this.ReportList(res.data)
      })
    },
    goodsCountChange(item) {
      const index = this.catList.findIndex((cat) => cat.catID === item.catID)
      const catItem = this.catList[index]
      const goodsIndex = catItem.goodsList.findIndex(
        (goods) => goods.id === item.id
      )

      catItem.goodsList[goodsIndex].count = item.count

      this.$set(this.catList, index, catItem)
    },
    doSort(item) {
      if (this.sortActive !== item.name) {
        if (this.sortActive) {
          const lastSort = this.sorts.find(
            (sort) => sort.name === this.sortActive
          )

          if (lastSort) {
            lastSort.type = 'default'
          }
        }

        this.sortActive = item.name

        item.type = 'desc'
      } else {
        switch (item.type) {
          case 'default':
            item.type = 'desc'
            break
          case 'desc':
            item.type = 'asc'
            break
          case 'asc':
            item.type = 'default'
            this.sortActive = ''
            break
          default:
            break
        }
      }

      this.sortType = item.type
    },
    handleSort(data, key, type) {
      data.sort((a, b) => a[key] - b[key])

      if (type === 'desc') {
        data.reverse()
      }

      return data
    },
    resetAll() {
      this.catList = this.catList.map((item) => {
        item.goodsList = item.goodsList.map((goods) => {
          goods.count = 0

          return goods
        })

        return item
      })
    },
    async submit() {
      const form = {
        month: this.month,
        cid: this.floor.id,
        catList: this.catList.map((cat) => {
          const goodsList = cat.goodsList.map((goods) => {
            return {
              id: goods.id,
              count: goods.count,
              price: goods.price,
            }
          })

          return {
            catID: cat.catID,
            goodsList,
          }
        }),
        priceTotal: this.priceTotal,
        employeesPriceTotal: this.employeesPriceTotal,
        billingAmount: this.billingAmount,
        needs: this.feedbackNeeds,
      }

      this.listLoading = true
      this.submitLoading = true

      const res = await this.$axios.post('/refrigerate/requests', form)

      this.ReportSubmit('/refrigerate/requests', form, res)

      this.listLoading = false
      this.submitLoading = false

      if (res.status !== 200) {
        return
      }

      this.$toast({
        message: '保存しました',
      })

      this.$router.push('/ody/request-complete')
    },
  },
}
