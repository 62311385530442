//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'PasswordresetSuccessPage',

  auth: false,
}
