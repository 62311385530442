//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { formatDate } from '@/plugins/filters'

export default {
  name: 'CalendarPage',

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from
    })
  },

  layout: 'page',

  data() {
    const { query } = this.$route

    return {
      floor: { id: query.floor },
      now: new Date(),
      date: new Date(),
      calendarData: [],
      holidays: [],
      statuses: [],
      downloadOptions: [
        {
          id: 'cpFlg',
          label: '企業価格表示',
        },
        {
          id: 'epFlg',
          label: '従業員価格表示',
        },
        {
          id: 'dmFlg',
          label: '全商品表示',
        },
      ],
      downloadParams: ['epFlg', 'dmFlg'],
      downloadImgs: {},
      downloadLoading: false,
    }
  },

  computed: {
    month() {
      return formatDate(this.date, 'yyyy-MM')
    },
    downloadQuerys() {
      return this.downloadParams.map((item) => `${item}=1`).join('&')
    },
    statusMap() {
      const map = {}

      this.statuses.forEach((item) => {
        map[item.id] = item
      })

      return map
    },
  },

  watch: {
    month() {
      this.fetchCalendar()
    },
  },

  mounted() {
    if (this.prevRoute.name === 'calendar-delivery-confirm') {
      this.date = this.$store.state.calendarDate
    }

    this.fetchCalendar()
  },

  methods: {
    async fetchCalendar() {
      const res = await this.$axios.$get('/refrigerate/calendar/info', {
        params: {
          month: this.month,
          cid: this.floor.id,
        },
      })

      const { calendar, holidays, floor, images, statuses } = res.data

      this.floor = floor
      this.calendarData = calendar
      this.holidays = holidays
      this.downloadImgs = images
      this.statuses = statuses
    },
    onSelectDate(value, data) {
      if (data && data.can_click) {
        this.$store.commit('updateCalendarDate', value)

        this.$router.push({
          path: '/calendar/delivery-confirm',
          query: {
            date: formatDate(value, 'yyyy-MM-dd'),
            floor: this.floor.id,
            floorName: this.floor.name,
          },
        })
      }
    },
    async download(url, title, intro, imgUrl) {
      this.downloadLoading = true

      try {
        await this.$confirm(
          `<div class="content-center content-custom">
            <img class="p-img" src="${imgUrl}" />
            <p class="p-intro">${intro}</p>
          </div>`,
          title,
          {
            dangerouslyUseHTMLString: true,
            confirmButtonText: 'ダウンロード',
            cancelButtonText: 'キャンセル',
            customClass: 'message-box-confirm confirm-normal',
            confirmButtonClass: 'el-button--primary',
            cancelButtonClass: 'el-button--text',
          }
        )
        this.$download(url)
      } catch (error) {}

      this.downloadLoading = false
    },
  },
}
