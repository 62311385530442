import { render, staticRenderFns } from "./error.vue?vue&type=template&id=7ceb094a&"
import script from "./error.vue?vue&type=script&lang=js&"
export * from "./error.vue?vue&type=script&lang=js&"
import style0 from "./error.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {StepsEnd: require('/home/runner/work/myody_front/myody_front/components/steps/StepsEnd.vue').default,Card: require('/home/runner/work/myody_front/myody_front/components/card/Card.vue').default})
