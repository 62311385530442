//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    value: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      content: this.value,
    }
  },

  watch: {
    value(newValue) {
      if (this.content !== newValue) {
        this.content = newValue
      }
    },
  },
}
