//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { formatDate } from '@/plugins/filters'

export default {
  name: 'OdoHistoryPage',

  layout: 'page',

  data() {
    return {
      list: [],
      listLoading: false,
      date: new Date(),
      dateMax: new Date(),
    }
  },

  mounted() {
    this.fetchList()
  },

  methods: {
    async fetchList() {
      this.listLoading = true

      const res = await this.$axios.$get('/odo/history', {
        params: {
          year: formatDate(this.date, 'yyyy'),
        },
      })

      this.listLoading = false

      const { historyList } = res.data

      this.list = historyList
    },
  },
}
