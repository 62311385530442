//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    cover: {
      type: String,
      default: '',
    },
    goodsTitle: {
      type: String,
      default: '',
    },
    price: {
      type: Number,
      default: null,
    },
    count: {
      type: Number,
      default: 0,
    },
    countMax: {
      type: Number,
      default: 10000,
    },
  },

  data() {
    return {
      countValue: this.count,
    }
  },

  watch: {
    count(newValue) {
      if (newValue !== this.countValue) {
        this.countValue = newValue
      }
    },
  },

  methods: {
    inputCount(input) {
      if (!Number.isInteger(Number(input.data))) {
        input.target.value = this.countValue
      }
    },
    selectCount(value) {
      this.$emit('count', value)
    },
  },
}
