//
//
//
//
//
//
//
//
//
//
//
//

import { Tag } from 'element-ui'

export default {
  components: {
    'el-tag': Tag,
  },

  props: {
    color: {
      type: String,
      default: null,
    },
    disableTransitions: {
      type: Boolean,
      default: true,
    },
    effect: {
      type: String,
      default: 'dark',
    },
  },

  computed: {
    style() {
      const style = {}

      if (this.color) {
        style.borderColor = this.color
        style.backgroundColor = this.color
      }

      return style
    },
  },
}
